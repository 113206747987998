import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {Actions, Context} from '../Contexts/AppContext';
import * as ModalContent from '../Templates/ProfilePage/AddrMgmt/ModalContent.addr';
import {useDimension} from './AppHooks';
import RectButton, {BUTTON_SKIN} from '../Components/RectButton';
import {CVS_TYPE_DATA, SHIPPING_TYPE} from '../Domain/Order';
import CheckButton from '../Templates/Checkout/CheckButton';
import TextInput from '../Components/TextInput';
import Select from '../Components/Select';
import * as L from '../Utils/Lang';
import {Row} from '../Templates/ProfilePage/BoxUI';
import {
  getPhoneValidator,
  getRequiredValidator,
  validateForm,
} from '../Utils/validateUtil';
import {VALIDATORS_BY_SHIPPING_TYPE} from '../Domain/Checkout';
import styled from 'styled-components';
import constants from '../constants';
import useAdministrativeRegions from './useAdministrativeRegions';
import {AddressItem} from '../Templates/ProfilePage/Orders/Return/AddressItem';
import Confirm from '../Components/Modal/Confirm';
import {AddCvsStoreButton} from '../Components/Address/AddCvsStoreButton';
import {AddAddressButton} from '../Components/Address/AddAddressButton';
import {Tooltip} from 'antd';

async function getCountryData(cityName) {
  if (!cityName) {
    return {
      counties: [],
      towns: [],
    };
  }

  const _counties = await Actions.getAllCounties();
  const _towns = await Actions.getAllTowns(
    // TODO: may only need one city
    _counties.map(county => county.county_code),
  );

  const city = _counties.find(c => c.county_name === cityName)?.county_code;
  const townsOfCounty = _towns[city];

  return {
    counties: _counties.map(county => ({
      value: county.county_code,
      display: county.county_name,
    })),
    towns: townsOfCounty.map(town => ({
      value: town.towncode,
      display: town.townname,
    })),
  };
}

const _convertExposedAddressInfoToStateValues = (
  exposedAddressInfo,
  countyOptions = [],
  townOptions = [],
) => {
  let _result = {};

  const _fieldMapping = {
    // exposed : internal
    shipping_type: 'shippingType',
    receiver_name: 'receiverName',
    receiver_phone: 'receiverPhone',
    cvs_number: 'storeId',
    cvs_store_name: 'storeName',
    cvs_store_type: 'cvsStoreType',
    city: 'city',
    district: 'town',
    address: type => (type === SHIPPING_TYPE.cvs ? 'storeAddress' : 'address'),
  };

  const _shippingType = exposedAddressInfo['shipping_type'];

  for (let key in exposedAddressInfo) {
    let _mappedKey =
      typeof _fieldMapping[key] === 'function'
        ? _fieldMapping[key](_shippingType)
        : _fieldMapping[key];

    if (_mappedKey === 'city') {
      let _city = exposedAddressInfo[key];
      _result[_mappedKey] =
        (_city &&
          countyOptions.find(county => county.display === _city)?.value) ||
        '';
    } else if (_mappedKey === 'town') {
      let _district = exposedAddressInfo[key];
      _result[_mappedKey] =
        (_district &&
          townOptions.find(town => town.display === _district)?.value) ||
        '';
    } else {
      _result[_mappedKey] = exposedAddressInfo[key];
    }
  }

  return _result;
};

export const openSelectCvsWindow = ({
  values,
  isFromModal = false,
  points = null,
  isUsePoints = false,
}) => {
  const merchantId = constants.merchantId;
  const serverReplyURL = `${constants.cvsMapServerHost}/tranzactions/ecpay_map_return`;
  const extraData = 'W';
  const merchatType = values.cvsStoreType;
  const merchantTradeNo = new Date().getTime().toString();

  const SELECT_CVS_STORE_URL = `https://logistics.ecpay.com.tw/Express/map?MerchantID=${merchantId}&LogisticsType=CVS&LogisticsSubType=${merchatType}&MerchantTradeNo=${merchantTradeNo}&IsCollection=Y&ServerReplyURL=${serverReplyURL}&ExtraData=${extraData}`;
  window.localStorage.setItem(
    'previousPage',
    `${window.location.pathname}${window.location.search}`,
  );

  // FIXME: should get actual shipping type from values, though we know shippingType is cvs so we're going to open select cvs...
  window.localStorage.setItem(
    `stored-addrs-data`,
    JSON.stringify({
      ...values,
      shippingType: SHIPPING_TYPE.cvs,
      _cachedIsUsedPoints: isUsePoints,
      _cachedPoints: points,
    }),
  );

  window.localStorage.setItem('isFromModal', isFromModal);
  window.open(SELECT_CVS_STORE_URL, '_self');
};

export const useAddressManager = ({
  isAllowCvsLogistic = false,
  defaultShippingType = SHIPPING_TYPE.home,
  defaultSelectedAddressId = null,
  initialData = null,
}) => {
  const app = useContext(Context);
  const {mobile} = useDimension();
  const [addrs, setAddrs] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(-1);

  React.useEffect(() => {
    const _initialized = async () => {
      let _initialData = {};
      if (initialData) {
        let {counties, towns} = await getCountryData(initialData.city);
        _initialData = _convertExposedAddressInfoToStateValues(
          initialData,
          counties,
          towns,
        );
      }

      if (defaultSelectedAddressId) {
        setSelectedAddressId(defaultSelectedAddressId);
      }

      setValues({
        ...values,
        ..._initialData,
        shippingType: defaultShippingType,
      });
    };

    _initialized();
  }, [defaultShippingType, initialData]);

  const [values, setValues] = useState({
    shippingType: '',
    receiverName: app.currentUser?.name || '',
    receiverPhone: app.currentUser?.phone || '',
    city: '',
    town: '',
    address: '',
    cvsStoreType: '',
    storeName: '',
    storeId: '',
    storeAddress: '',
  });

  const [errors, setErrors] = useState({});
  const {townOptions, countyOptions} = useAdministrativeRegions(values.city);
  const getValue = field => values[field];
  const getError = field => errors[field];
  const validators = useMemo(
    () => ({
      receiverName: getRequiredValidator(),
      receiverPhone: getPhoneValidator(),
      ...VALIDATORS_BY_SHIPPING_TYPE[values.shippingType],
    }),
    [values.shippingType],
  );
  const onBlur = field => e => {
    setErrors({
      ...errors,
      [field]: validators[field].passRequest(values[field]),
    });
  };
  const onValueChange = field => e => {
    setValues({...values, [field]: e.target.value});
  };

  const getIsAddressFormAllPass = useCallback(() => {
    return validateForm({values, validators, errors, setErrors});
  }, [errors, validators, values]);

  const onCvsStoreTypeSelect = e => {
    setValues({
      ...values,
      cvsStoreType: e.target.value,
      storeName: '',
      storeAddress: '',
      storeId: '',
    });
  };

  useEffect(() => {
    const addr = addrs.find(addr => addr.id === selectedAddressId);
    if (!addr) {
      return;
    }
    if (addr.shippingType === SHIPPING_TYPE.home) {
      setValues(values => ({
        ...values,
        shippingType: addr.shippingType,
        receiverName: addr.receiverName,
        receiverPhone: addr.receiverPhone,
        city: countyOptions.find(opt => opt.display === addr.city)?.value || '',
        town:
          townOptions.find(opt => opt.display === addr.district)?.value || '',
        address: addr.detail,
      }));
    } else {
      setValues(values => ({
        ...values,
        shippingType: addr.shippingType,
        receiverName: addr.receiverName,
        receiverPhone: addr.receiverPhone,
        cvsStoreType: addr.storeType,
        storeName: addr.storeName,
        storeId: addr.storeId,
        storeAddress: addr.storeAddr,
      }));
    }
  }, [addrs, countyOptions, selectedAddressId, townOptions]);

  const onShippingTypeSelect = value => {
    setErrors({
      ...errors,
      address: null,
      city: null,
      town: null,
      storeName: null,
      storeAddress: null,
      storeId: null,
      cvsStoreType: null,
    });
    setValues({
      ...values,
      shippingType: value,
      address: '',
      city: '',
      town: '',
      storeName: '',
      storeAddress: '',
      storeId: '',
      cvsStoreType: '',
    });
    setSelectedAddressId(-1);
  };

  const addressInfo = useMemo(() => {
    const common = {
      shipping_type: values.shippingType,
      receiver_name: values.receiverName,
      receiver_phone: values.receiverPhone,
    };

    return values.shippingType === SHIPPING_TYPE.cvs
      ? {
          cvs_number: values.storeId,
          cvs_store_name: values.storeName,
          address: values.storeAddress,
          cvs_store_type: values.cvsStoreType,
          ...common,
        }
      : {
          city:
            countyOptions.find(county => county.value === values.city)
              ?.display || '',
          district:
            townOptions.find(town => town.value === values.town)?.display || '',
          address: values.address,
          ...common,
        };
  }, [countyOptions, townOptions, values]);

  const fetchAddress = useCallback(async () => {
    try {
      if (!app.currentUser) {
        return;
      }

      const address = await Actions.fetchMyAddrs();
      setAddrs(address);
    } catch (ex) {
      console.warn(ex);
    }
  }, [app.currentUser]);

  useEffect(() => {
    fetchAddress();
  }, [fetchAddress]);

  useEffect(() => {
    if (!app.runtimeProfileCvsData || !app.currentUser) {
      return;
    }

    if (app.runtimeProfileCvsData.shippingType) {
      setValues({
        ...values,
        shippingType: app.runtimeProfileCvsData.shippingType,
      });
    }

    if (!window.localStorage.isFromModal) {
      setValues({...app.runtimeProfileCvsData});
      Actions.setRuntimeProfileCvsData(null);
    } else {
      Actions.setGlobalModalContent(
        <ModalContent.AddCvsStoreForm fetchAddress={fetchAddress} />,
      );
    }

    window.localStorage.removeItem('isFromModal');
  }, [app.currentUser, app.runtimeProfileCvsData, fetchAddress, values]);

  useEffect(() => {
    if (!addrs) {
      return;
    }

    if (selectedAddressId === -1) {
      const defaultAddr = addrs.find(
        addr => addr.shippingType === values.shippingType && addr.primary,
      );
      const defaultAddrId = defaultAddr ? defaultAddr.id : -1;
      setSelectedAddressId(defaultAddrId);
    }
  }, [addrs, values.shippingType, selectedAddressId]);

  const AddressFormElem = (
    <div>
      <Row>
        <CheckButton
          style={{flex: 1}}
          checked={values.shippingType === SHIPPING_TYPE.home}
          onClick={() => {
            onShippingTypeSelect(SHIPPING_TYPE.home);
          }}>
          宅配
        </CheckButton>

        <div style={{width: 12}} />

        {isAllowCvsLogistic ? (
          <CheckButton
            style={{flex: 1}}
            checked={values.shippingType === SHIPPING_TYPE.cvs}
            disabled={!isAllowCvsLogistic}
            onClick={() => onShippingTypeSelect(SHIPPING_TYPE.cvs)}>
            超商取貨
          </CheckButton>
        ) : (
          <Tooltip title="購買的商品金額超過超商取貨限制" placement="bottom">
            <CheckButton
              style={{flex: 1}}
              checked={values.shippingType === SHIPPING_TYPE.cvs}
              disabled={!isAllowCvsLogistic}
              onClick={() => onShippingTypeSelect(SHIPPING_TYPE.cvs)}>
              超商取貨
            </CheckButton>
          </Tooltip>
        )}
      </Row>

      <div style={{margin: '4px 0 20px 0'}}>
        <div>
          <b style={{fontSize: 12}}>
            {values.shippingType === SHIPPING_TYPE.cvs
              ? '訂單成立後 3~5 個工作天送達指定門市'
              : '訂單成立後 1~2 個工作天到貨，週日、國定假日不提供服務'}
          </b>
        </div>
        <div>
          <b style={{fontSize: 12}}>
            若您購買多個品牌的商品，您的包裹將依照品牌分批到貨
          </b>
        </div>
      </div>
      {selectedAddressId === -1 ? (
        <Fragment>
          <Row style={{marginBottom: mobile ? 0 : 24}} mobile={mobile}>
            <TextInput
              label="收件人姓名"
              value={getValue('receiverName')}
              error={getError('receiverName')}
              onChange={onValueChange('receiverName')}
              onBlur={onBlur('receiverName')}
              style={{flex: 1, marginBottom: mobile ? 24 : 0}}
            />

            {!mobile && <div style={{width: 12}} />}

            <TextInput
              label="收件人手機"
              maxLength={10}
              value={getValue('receiverPhone')}
              error={getError('receiverPhone')}
              onChange={onValueChange('receiverPhone')}
              onBlur={onBlur('receiverPhone')}
              style={{flex: 1, marginBottom: mobile ? 24 : 0}}
            />
          </Row>

          {values.shippingType === SHIPPING_TYPE.cvs ? (
            <Row style={{marginBottom: mobile ? 0 : 24}} mobile={mobile}>
              <Select
                label="取件超商"
                options={[
                  {
                    value: CVS_TYPE_DATA.FAMIC2C.key,
                    display: L.s(CVS_TYPE_DATA.FAMIC2C.display_key),
                  },
                  {
                    value: CVS_TYPE_DATA.UNIMARTC2C.key,
                    display: L.s(CVS_TYPE_DATA.UNIMARTC2C.display_key),
                  },
                  {
                    value: CVS_TYPE_DATA.HILIFEC2C.key,
                    display: L.s(CVS_TYPE_DATA.HILIFEC2C.display_key),
                  },
                ]}
                value={getValue('cvsStoreType')}
                error={getError('cvsStoreType') || getError('storeId')}
                onChange={onCvsStoreTypeSelect}
                onBlur={onBlur('cvsStoreType')}
                style={{flex: 1, marginBottom: mobile ? 24 : 0}}
              />

              {!mobile && <div style={{width: 12}} />}

              {values.storeId ? (
                <CvsStoreBlock>
                  <div className="icon">
                    <img
                      src={CVS_TYPE_DATA[values.cvsStoreType].iconPath}
                      alt="cvs icon"
                    />
                  </div>
                  <div className="detail">
                    <div className="title">{values.storeName}</div>
                    <div className="address">{values.storeAddress}</div>
                  </div>
                  <div
                    className="action-btn"
                    onClick={() => {
                      openSelectCvsWindow({
                        values,
                        points: app.points,
                        isUsePoints: app.isUsePoints,
                      });
                    }}>
                    變更
                  </div>
                </CvsStoreBlock>
              ) : (
                <RectButton
                  text="選擇門市"
                  skin={BUTTON_SKIN.PRIMARY}
                  isDisabled={!values.cvsStoreType}
                  onClick={() => {
                    openSelectCvsWindow({
                      values,
                      points: app.points,
                      isUsePoints: app.isUsePoints,
                    });
                  }}
                />
              )}
            </Row>
          ) : (
            <Fragment>
              <Row style={{marginBottom: mobile ? 0 : 24}} mobile={mobile}>
                <Select
                  label="縣市"
                  options={countyOptions}
                  value={getValue('city')}
                  error={getError('city')}
                  onChange={onValueChange('city')}
                  onBlur={onBlur('city')}
                  style={{flex: 1, marginBottom: mobile ? 24 : 0}}
                />

                {!mobile && <div style={{width: 12}} />}

                <Select
                  disabled={values.city === ''}
                  label="鄉鎮"
                  options={townOptions}
                  value={getValue('town')}
                  error={getError('town')}
                  onChange={onValueChange('town')}
                  onBlur={onBlur('town')}
                  style={{flex: 1, marginBottom: mobile ? 24 : 0}}
                />
              </Row>

              <TextInput
                label="地址"
                value={getValue('address')}
                error={getError('address')}
                onChange={onValueChange('address')}
                onBlur={onBlur('address')}
                style={{width: '100%', marginBottom: mobile ? 0 : 64}}
              />
            </Fragment>
          )}
        </Fragment>
      ) : (
        <AddressItem
          setSelectedAddressId={setSelectedAddressId}
          initSelectedAddrId={selectedAddressId}
          addrs={addrs}
          item={addrs.find(addr => addr.id === selectedAddressId) || {}}
          fetchAddress={fetchAddress}
          AddCvsStoreButton={AddCvsStoreButton}
          AddAddressButton={AddAddressButton}
          editButtonWording="變更收件地址"
        />
      )}
    </div>
  );

  return {
    addrs,
    fetchAddress,
    getIsAddressFormAllPass,
    AddressFormElem,
    addressInfo,
    selectedAddressId,
    setSelectedAddressId,
  };
};

const CvsStoreBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;

  & > .icon {
    width: 32px;
    height: 32px;

    & > img {
      width: 100%;
      height: 100%;
    }
  }
  & > .detail {
    margin-left: 12px;
    margin-right: 20px;

    & > .title {
      color: #141414;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
    }

    & .address {
      font-size: 12px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: rgba(20, 20, 20, 0.7);
    }
  }
  & > .action-btn {
    font-size: 14px;
    text-decoration: underline;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: right;
    color: #141414;
    cursor: pointer;
  }

  background-color: rgba(20, 20, 20, 0.03);
`;

export const openConfirmRemoveModal = ({id, fetchAddress}) => {
  Actions.setGlobalModalContent(
    <Confirm
      onClick={async () => {
        await Actions.deleteAddress(id);
        fetchAddress();
        Actions.setGlobalModalContent(null);
      }}
      cancelLabel="取消"
      confirmLabel="確定"
      title="確定要刪除此地址嗎？"
    />,
  );
};

export const openConfirmSetPrimaryAddressModal = ({
  item,
  fetchAddress,
  setSelectedAddressId = null,
}) => {
  Actions.setGlobalModalContent(
    <Confirm
      cancelLabel="取消"
      confirmLabel="預設地址"
      title="確定要設為預設定址？"
      onClick={async () => {
        const isSuccess = await Actions.updateAddress({
          id: item.id,
          receiver_name: item.receiverName,
          receiver_phone: item.receiverPhone,
          city: item.city,
          district: item.town,
          detail:
            item.shippingType === SHIPPING_TYPE.home
              ? item.detail
              : item.storeAddr,
          store_id: item.storeId,
          store_name: item.storeName,
          store_type: item.storeType && item.storeType.toLocaleLowerCase(),
          address_type: item.shippingType,
          default: true,
        });

        if (!isSuccess) {
          alert('預設地址失敗');
          return;
        }

        if (setSelectedAddressId) {
          setSelectedAddressId(item.id);
        }

        fetchAddress();
        Actions.setGlobalModalContent(null);
      }}
    />,
  );
};
