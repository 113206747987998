import React, {useContext, useEffect, useState} from 'react';
import {Context, NavActions} from '../Contexts/AppContext';
import {Color, FlexColCenter} from '../Components/Widget';
import RectButton, {BUTTON_SKIN} from '../Components/RectButton';

// FIXME: do not bind ui element with custom hook. do this only when the ui comp is REALLY general.
export const useLoginRequired = ({hintEle = null, wrapperStyle = {}} = {}) => {
  const app = useContext(Context);
  const isLogin = !!app.currentUser;

  const loginRedirectElem = (
    <FlexColCenter
      style={{
        height: '117px',
        width: '100%',
        justifyContent: 'space-evenly',
        borderBottom: `1px solid ${Color.mainDark_30}`,
        ...wrapperStyle,
      }}>
      {hintEle || <div>立即登入，查看完整的資訊！</div>}
      <RectButton
        style={{width: '90%'}}
        skin={BUTTON_SKIN.PRIMARY}
        text="登入 / 註冊"
        onClick={() => {
          NavActions.navigate('/login');
        }}
      />
    </FlexColCenter>
  );

  return {
    isLogin,
    loginRedirectElem,
  };
};
