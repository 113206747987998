import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {useDimension} from '../../Hooks/AppHooks';
import CheckoutPanel from './CheckoutPanel';
import Support from './Support';
import Modal from './Modal';
import CheckButton from './CheckButton';
import TextInput from '../../Components/TextInput';
import Select from '../../Components/Select';
import {
  Actions,
  Context as AppContext,
  NavActions,
} from '../../Contexts/AppContext';
import * as L from '../../Utils/Lang';
import {
  CREDIT_CARD_TYPE_PIC,
  INVOICE_SUBTYPE,
  SHIPPING_TYPE,
} from '../../Domain/Order';
import creditCardType from 'credit-card-type';
import {
  CheckBox,
  Color,
  FlexCol,
  FlexRow,
  SeparateLine,
  UnderlineButton,
} from '../../Components/Widget';
import {getRequiredValidator, validateForm} from '../../Utils/validateUtil';
import {
  PAYMENT_TYPE,
  VALIDATORS_BY_INVOICE_SUBTYPE,
  VALIDATORS_BY_PAYMENT_TYPE,
} from '../../Domain/Checkout';
import FixedRatioImage from '../../Components/FixedRatioImage';
import _ from 'lodash';
import {
  brandIdToBrandName,
  brandNameToBrandId,
  brandsDisplayArr,
  brandsObj,
} from '../../Domain/Brand';
import {getFormatPrice, getPrice, getPriceTag} from '../../Utils/ProductUtil';
import {isAllowCvsLogistic} from '../../Utils/appLogic';
import useFavStores from '../../Hooks/useFavStores';
import FavStoreBlock from './FavStoreBlock';
import {useCreditCards} from '../../Hooks/CreditCardsHooks';
import {useAddressManager} from '../../Hooks/useAddressManager';
import {useLoginRequired} from '../../Hooks/useLoginRequired';
import CustomNav from '../../Components/CustomNav';
import {ChevronLeft, LogoTungrp} from '../../Components/SvgIcon';
import ModalContentInstallmentHint from '../../Components/ModalContent.installmentHint';
import {MEMBERSHIP_CONFIG, MEMBERSHIP_LEVEL_DISPLAY} from '../../Domain/Member';
import {PickerButton} from './PickerButton';
import OverTime from '../../images/illustration-time.svg';
import Alert from '../../Components/Modal/Alert';
import {SelectedCreditPicker} from './SelectedCreditPicker';
import calcDistance from '../../Utils/calcDistance';

function CheckoutPage() {
  const modalRef = useRef();

  // HINT: apply this breakpoint in special case , do not change this
  const {dimension} = useDimension();
  const mobile = dimension.innerWidth <= 640;
  const tablet = !mobile && dimension.innerWidth <= 1024;
  const smallDevice = mobile || tablet;

  const app = useContext(AppContext);
  const [addCreditCardForm, setAddCreditCardForm] = useState('');
  const {myCreditCards} = useCreditCards();
  const {isLogin, loginRedirectElem} = useLoginRequired();
  const {favStores, fetchFavStores} = useFavStores();
  const [values, setValues] = useState({
    payment: PAYMENT_TYPE.all,
    installment: 0,
    note: '',
    invoiceType:
      window.localStorage.getItem('invoice_type') || INVOICE_SUBTYPE.ecpay,
    invoiceCarrier: window.localStorage.getItem('invoice_carrier') || '/',
    invoiceTax: window.localStorage.getItem('invoice_tax') || '',
    selectedCreditCardId: '',
  });
  const [errors, setErrors] = useState({});
  const [isCheckoutApiLoading, setIsCheckoutApiLoading] = useState(false);
  const [isAllowInvoiceLegal, setIsAllowInvoiceLegal] = useState(true);
  const [
    isAllowInvoiceLegalErrorMsg,
    setIsAllowInvoiceLegalErrorMsg,
  ] = useState('');

  const itemsGroupByBrandId = useMemo(() => {
    if (!app.cart) {
      return;
    }
    return _.groupBy(app.cart.items, item => {
      return item.variant.product.brand_id;
    });
  }, [app.cart]);

  const _isAllowCvsLogistic = React.useMemo(() => {
    const itemsOfBrands = _.values(itemsGroupByBrandId).map(items =>
      items.map(item => {
        const isPromote = !!item.variant.product.promotion;
        const isMember = !!app.currentUser;
        const finalPrice = getPrice(
          {
            price: item.variant.price,
            member_price: item.variant.member_price,
            promote_price: item.variant.promote_price,
          },
          isPromote,
          isMember,
        );
        return {quantity: item.quantity, price: finalPrice};
      }),
    );
    return isAllowCvsLogistic({
      totalPrice: app.cart?.price_detail.price || 0,
      itemsOfBrands,
    });
  }, [app.cart?.price_detail.price, app.currentUser, itemsGroupByBrandId]);

  const {
    getIsAddressFormAllPass,
    AddressFormElem,
    addressInfo,
    selectedAddressId,
  } = useAddressManager({
    isAllowCvsLogistic: _isAllowCvsLogistic,
    defaultSelectedAddressId: values._cachedDefaultAddressId,
    defaultShippingType: values._cachedShippingType || SHIPPING_TYPE.home,
    initialData: values._cachedAddressData || null,
  });

  const onAddressDebounce = async (location, stores, _fetchFavStores) => {
    const _favStores = await _fetchFavStores();
    const _brandsThatAlreadySetFav =
      _favStores?.filter(store => !!store.storeId).map(store => store.brand) ||
      [];

    if (_brandsThatAlreadySetFav.length === Object.keys(brandsObj).length) {
      // INFO: because all store is set to default not tot set fav stores anymore.
      return;
    }

    try {
      Actions.setLoading(true);
      const searchResult = await Actions.fetchGeometry(location);
      let result = {};
      for (let key in stores) {
        result[key] = stores[key]
          .map(s => ({
            store_id: s.id,
            distance: calcDistance(searchResult.geometry.location, {
              lat: s.lat,
              lng: s.lng,
            }),
          }))
          .sort((a, b) => a.distance - b.distance)[0];
      }

      const _brandsShouldBeSetFavStores = Object.keys(stores).filter(
        _storeBrand => {
          return !_brandsThatAlreadySetFav.includes(_storeBrand);
        },
      );

      await Promise.all(
        _brandsShouldBeSetFavStores.map(b =>
          Actions.setFavStore({
            brand_id: brandNameToBrandId[b],
            store_id: result[b].store_id,
          }),
        ),
      );

      await _fetchFavStores();
    } finally {
      Actions.setLoading(false);
    }
  };

  const onAddressChange = useMemo(
    () =>
      _.debounce(async (location, stores, _fetchFavStores) => {
        const l =
          location.shipping_type === SHIPPING_TYPE.cvs
            ? location.address
            : `${location.city || ''}${location.town || ''}${location.address ||
                ''}`;
        if (!l) {
          return;
        }
        await onAddressDebounce(l, stores, _fetchFavStores);
      }, 1000),
    [],
  );

  useEffect(() => {
    if (
      !addressInfo ||
      !app.allStoreData ||
      Object.keys(app.allStoreData || {}).length < 5
    ) {
      return;
    }
    onAddressChange(addressInfo, app.allStoreData, fetchFavStores);
  }, [addressInfo, app.allStoreData, fetchFavStores, onAddressChange]);

  const validators = useMemo(
    () => ({
      selectedCreditCardId: getRequiredValidator(),
      ...VALIDATORS_BY_INVOICE_SUBTYPE[values.invoiceType],
      ...VALIDATORS_BY_PAYMENT_TYPE[values.payment],
    }),
    [values.invoiceType, values.payment],
  );

  const getValue = field => values[field];
  const getError = field => errors[field];

  const onValidate = field => () => {
    setErrors(prev => ({
      ...prev,
      [field]: validators[field].passRequest(values[field]),
    }));
  };

  const onValueChange = field => e => {
    e.persist();
    if (field === 'invoiceCarrier') {
      if (e.target.value === '') {
        setValues(prev => ({...prev, [field]: '/'}));
      } else {
        setValues(prev => ({...prev, [field]: e.target.value}));
      }
    } else {
      setValues(prev => ({...prev, [field]: e.target.value}));
    }
  };

  const timeoutId = useRef(0);

  const doInactive = useCallback(() => {
    Actions.setGlobalModalContent(
      <Alert
        icon={<img src={OverTime} alt="over time" />}
        title="您的操作已逾時"
        subTitle="此頁面已閒置一段時間，為了帳戶安全，已中斷您的結帳流程"
        cancelLabel="返回購物車"
        onClick={() => {
          NavActions.navigate('/cart');
          Actions.setGlobalModalContent(null);
        }}
      />,
    );
    window.clearTimeout(timeoutId.current);
  }, []);

  const startTimer = useCallback(() => {
    const thirtyMins = 1000 * 60 * 30;
    timeoutId.current = window.setTimeout(doInactive, thirtyMins);
  }, [doInactive]);

  const resetTimer = useCallback(() => {
    window.clearTimeout(timeoutId.current);
    startTimer();
  }, [startTimer]);

  useEffect(() => {
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('mousedown', resetTimer);
    window.addEventListener('keypress', resetTimer);
    window.addEventListener('touchmove', resetTimer);
    startTimer();
    return () => {
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('mousedown', resetTimer);
      window.removeEventListener('keypress', resetTimer);
      window.removeEventListener('touchmove', resetTimer);
      window.clearTimeout(timeoutId.current);
    };
  }, [resetTimer, startTimer]);

  useEffect(() => {
    const restoreCheckoutData = async () => {
      try {
        Actions.setLoading(true);
        let _localDataStr = window.localStorage.getItem('checkout-data');
        let _localData = null;
        let _tempData = Actions.getRuntimeCheckoutData();

        try {
          if (_localDataStr) {
            _localData = JSON.parse(_localDataStr);
            window.localStorage.removeItem('checkout-data');
          }
        } catch (err) {
          //
        }

        setValues(prev => ({
          ...prev,
          ...(_localData ? _localData : {}),
          ...(_tempData ? _tempData : {}),
        }));

        if (!!_localData) {
          try {
            await Actions.setIsUsePoints(
              _localData?._cachedIsUsedPoints || false,
            );
            await Actions.setPoints({
              useBirthGiftPoints: _localData?._cachedPoints.useBirthGiftPoints,
              useRebatePoints: _localData?._cachedPoints.useRebatePoints,
              isUsePoints: _localData?._cachedIsUsedPoints || false,
            });
          } catch (e) {
            console.log(e);
            await Actions.setIsUsePoints(false);
            await Actions.setPoints({
              useBirthGiftPoints: 0,
              useRebatePoints: 0,
              isUsePoints: false,
            });
          }
        }
        Actions.setRuntimeCheckoutData(null);
      } catch (e) {
        console.log(e);
      } finally {
        Actions.setLoading(false);
      }
    };
    restoreCheckoutData().then(() => {
      console.log('restore checkout data done');
    });
  }, []);

  useEffect(() => {
    if (myCreditCards.length > 0) {
      const defaultSelectedCreditCardId =
        myCreditCards.find(c => c.is_primary)?.id || myCreditCards[0].id;
      setValues(values => ({
        ...values,
        selectedCreditCardId: defaultSelectedCreditCardId,
      }));
    }
  }, [myCreditCards]);

  const disabledCheckoutByOverMax = useMemo(() => {
    if (!app.cart) {
      return true;
    }
    return app.cart.price_detail.price > 200000;
  }, [app.cart]);

  const disabledCheckout = useMemo(() => {
    if (!app.cart) {
      return true;
    }
    return !!app.cart.items.find(i => {
      return (
        i.quantity > i.variant.stock ||
        !i.variant.product.published ||
        i.variant.stock <= 0
      );
    });
  }, [app.cart]);

  const setPrimaryAddress = async (_addressInfo, _selectedAddressId) => {
    try {
      let _addr = null;
      const isHome = _addressInfo.shipping_type === SHIPPING_TYPE.home;
      if (isHome) {
        _addr = {
          receiver_name: _addressInfo.receiver_name,
          receiver_phone: _addressInfo.receiver_phone,
          city: _addressInfo.city,
          district: _addressInfo.district,
          detail: _addressInfo.address,
          address_type: _addressInfo.shipping_type,
          default: true,
        };
      } else {
        _addr = {
          receiver_name: _addressInfo.receiver_name,
          receiver_phone: _addressInfo.receiver_phone,
          store_id: _addressInfo.cvs_number,
          store_name: _addressInfo.cvs_store_name,
          store_type: _addressInfo.cvs_store_type,
          address_type: _addressInfo.shipping_type,
          default: true,
        };
      }
      if (_selectedAddressId <= -1) {
        await Actions.createAddress(_addr);
      }
    } catch (e) {
      console.log('debug', e);
    }
  };

  const setDefaultInvoiceInfo = _values => {
    const isMobileCarrier = _values.invoiceType === INVOICE_SUBTYPE.mobile;
    const isB2B = _values.invoiceType === INVOICE_SUBTYPE.b2b;
    window.localStorage.setItem('invoice_type', _values.invoiceType);
    if (isMobileCarrier) {
      window.localStorage.setItem('invoice_carrier', _values.invoiceCarrier);
    } else if (isB2B) {
      window.localStorage.setItem('invoice_tax', _values.invoiceTax);
    }
  };

  const onCheckout = useCallback(async () => {
    try {
      if (!app.cart) {
        return;
      }
      if (disabledCheckoutByOverMax) {
        Actions.setGlobalModalContent(
          <Alert
            style={{margin: 'auto'}}
            icon={null}
            title="結帳金額上限"
            subTitle="結帳金額上限為 NT$200,000，請將部份商品分批購買"
            cancelLabel="返回購物車"
            onClick={() => {
              NavActions.navigate('/cart');
              Actions.setGlobalModalContent(null);
            }}
          />,
        );
        return;
      }

      if (disabledCheckout) {
        Actions.setGlobalModalContent(
          <Alert
            style={{margin: 'auto'}}
            icon={null}
            title="商品庫存不足"
            subTitle="購物車中有商品的庫存不足，請回到購物車移除或調整商品數量。"
            cancelLabel="返回購物車"
            onClick={() => {
              NavActions.navigate('/cart');
              Actions.setGlobalModalContent(null);
            }}
          />,
        );
        return;
      }

      if (!isAllowInvoiceLegal) {
        setIsAllowInvoiceLegalErrorMsg('請勾選同意');
        return;
      } else {
        setIsAllowInvoiceLegalErrorMsg('');
      }

      const isAllPass = validateForm({
        values,
        errors,
        setErrors,
        validators,
      });

      const isAddressFormAllPass = getIsAddressFormAllPass();

      if (!isAllPass || !isAddressFormAllPass) {
        return;
      }

      const getPoints = isUsePoints =>
        isUsePoints
          ? {
              use_birth_gift: app.points.useBirthGiftPoints,
              use_rebate_points: app.points.useRebatePoints,
            }
          : {
              use_birth_gift: 0,
              use_rebate_points: 0,
            };

      const data = {
        items: app.cart.items.map(item => ({
          variant_id: item.variant.id,
          quantity: item.quantity,
        })),
        credit_card_id: values.selectedCreditCardId,
        e_gui_type: values.invoiceType,
        e_gui_tax_number: values.invoiceTax,
        e_gui_carrier:
          values.invoiceType === INVOICE_SUBTYPE.mobile
            ? values.invoiceCarrier
            : undefined,
        note: values.note,
        use_birth_gift: app.points.useBirthGiftPoints,
        use_rebate_points: app.points.useRebatePoints,
        installment: values.installment,
        ...getPoints(app.isUsePoints),
        ...addressInfo,
      };

      await setPrimaryAddress(addressInfo, selectedAddressId);

      setDefaultInvoiceInfo(values);

      setIsCheckoutApiLoading(true);

      const resp = await Actions.checkout(data);

      await Actions.setPoints({
        useBirthGift: null,
        useRebatePoints: null,
        isUsePoints: false,
      });
      await Actions.fetchMe();

      const {message, success} = resp;
      if (success) {
        if (message) {
          alert(
            '綠界刷卡付款失敗，請先確認您的網路連線，如仍有問題請洽您的信用卡發卡銀行。',
          );
          NavActions.navigate(
            `/profile?active=orders&orderId=${resp.order.number}&action=re_checkout`,
          );
          return;
        }
      }

      NavActions.navigate('/profile?active=orders');

      if (!resp.success) {
        throw new Error('fail to checkout!');
      }
    } catch (err) {
      alert('結帳失敗');
    } finally {
      setIsCheckoutApiLoading(false);
    }
  }, [
    app.cart,
    app.points.useBirthGiftPoints,
    app.points.useRebatePoints,
    app.isUsePoints,
    disabledCheckoutByOverMax,
    disabledCheckout,
    isAllowInvoiceLegal,
    values,
    errors,
    validators,
    getIsAddressFormAllPass,
    addressInfo,
    selectedAddressId,
  ]);

  const addCreditCard = useCallback(async () => {
    const resp = await Actions.getBindCreditCardForm('/checkout');
    const form = resp.form_html;
    setAddCreditCardForm(form);
  }, []);

  const openInstallmentHintModal = () => {
    Actions.setGlobalModalContent(
      <ModalContentInstallmentHint
        mobile={smallDevice}
        onConfirm={() => 0}
        onClose={() => Actions.setGlobalModalContent(null)}
      />,
    );
  };

  useEffect(() => {
    if (addCreditCardForm) {
      window.localStorage.setItem(
        'previousPage',
        `${window.location.pathname}${window.location.search}`,
      );
      window.localStorage.setItem(
        `checkout-data`,
        JSON.stringify({
          ...values,
          _cachedShippingType: addressInfo.shipping_type,
          _cachedAddressData: addressInfo,
          _cachedDefaultAddressId: selectedAddressId,
          _cachedIsUsedPoints: app.isUsePoints,
          _cachedPoints: app.points,
        }),
      );
      const form = document
        .getElementById('add-credit-card-hidden-form')
        .querySelector('form[name="ecpay"]');
      form.submit();
    }
  }, [
    addCreditCardForm,
    addressInfo,
    app.isUsePoints,
    app.points,
    selectedAddressId,
    values,
  ]);

  useEffect(() => {
    setValues(val => ({
      ...val,
      invoiceType:
        window.localStorage.getItem('invoice_type') || INVOICE_SUBTYPE.ecpay,
      invoiceCarrier: window.localStorage.getItem('invoice_carrier') || '/',
      invoiceTax: window.localStorage.getItem('invoice_tax') || '',
    }));
  }, []);

  useEffect(() => {
    setValues(val => ({...val, town: ''}));
  }, [values.city]);

  const getPriceFlag = ({product, isMember, isPromote}) => {
    if (!product) {
      return 'none';
    }
    if (!isMember && !isPromote) {
      return 'none';
    } else if (isMember && isPromote) {
      return product.promote_price <= product.member_price
        ? 'promote'
        : 'member';
    } else if (isMember && !isPromote) {
      return 'member';
    } else {
      return 'promote';
    }
  };

  const getMemberDiscountRatio = product => {
    if (!app.currentUser) {
      return 0;
    }
    return MEMBERSHIP_CONFIG[app.currentUser.vip_level].discount[
      brandIdToBrandName[product.brand_id]
    ];
  };

  if (!isLogin) {
    return <Fragment>{loginRedirectElem}</Fragment>;
  }

  if (!app.cart || favStores.length === 0) {
    return null;
  }

  const stepsElem = (
    <Fragment>
      <div className="step">
        <h2>1. 運送資訊</h2>
        {AddressFormElem}
      </div>

      <div className="step" style={{marginTop: 64}}>
        <h2>2. 付款方式</h2>

        <Row mobile={smallDevice}>
          <CheckButton
            style={{flex: 1, margin: '8px 0'}}
            checked={values.payment === PAYMENT_TYPE.all}
            onClick={() =>
              setValues(prev => ({
                ...prev,
                payment: PAYMENT_TYPE.all,
                installment: 0,
              }))
            }>
            全額支付
          </CheckButton>

          <div style={{width: 12}} />

          <CheckButton
            style={{flex: 1, margin: '8px 0'}}
            checked={values.payment === PAYMENT_TYPE.staged}
            onClick={() => {
              setValues(prev => ({
                ...prev,
                payment: PAYMENT_TYPE.staged,
                installment: '',
              }));
            }}
            disabled={app.cart.subtotal < 5000}>
            分期零利率
          </CheckButton>
        </Row>
        {values.payment === PAYMENT_TYPE.staged && (
          <Select
            label="付款分期"
            options={[
              {
                value: 3,
                display: `NT ${getFormatPrice(
                  Math.round(app.cart.subtotal / 3),
                )} * 3 期零利率`,
              },
              {
                value: 6,
                display: `NT ${getFormatPrice(
                  Math.round(app.cart.subtotal / 6),
                )} * 6 期零利率`,
                disabled: app.cart.subtotal < 10000,
              },
            ]}
            error={getError('installment')}
            value={getValue('installment')}
            onChange={onValueChange('installment')}
            style={{flex: 1, marginBottom: smallDevice ? 24 : 0}}
          />
        )}

        <p style={{margin: '4px 0 20px 0'}}>
          <b>
            滿 $5,000 可使用 0 利率分期付款，點擊了解
            <UnderlineButton
              style={{fontSize: 12, fontWeight: 'bold'}}
              onClick={openInstallmentHintModal}>
              支援期數 / 銀行
            </UnderlineButton>
          </b>
        </p>

        {!smallDevice && myCreditCards.length > 0 && (
          <div className="credit-cards">
            <small style={{color: 'rgba(20,20,20,0.7)'}}>請選擇信用卡</small>
            <div>
              {myCreditCards.map((card, index) => {
                return (
                  <CheckButton
                    checked={card.id === values.selectedCreditCardId}
                    key={index}
                    style={{margin: '8px 0', border: 'none', paddingLeft: 0}}
                    onClick={() =>
                      setValues(prev => ({
                        ...prev,
                        selectedCreditCardId: card.id,
                      }))
                    }>
                    <FlexRow>
                      {
                        CREDIT_CARD_TYPE_PIC[
                          creditCardType(card.first_six)[0].type
                        ]
                      }
                      * {card.last_four}
                    </FlexRow>
                  </CheckButton>
                );
              })}
            </div>
          </div>
        )}

        {!smallDevice && (
          <div
            style={{
              marginBottom: smallDevice ? 0 : 64,
            }}>
            <AddCardButton
              onClick={addCreditCard}
              mobile={smallDevice}
              style={{
                width: smallDevice ? '100%' : 'auto',
                color: errors.selectedCreditCardId ? Color.red : Color.gold,
              }}>
              + 新增信用卡
            </AddCardButton>
            <div style={{paddingLeft: 20, fontSize: 12, fontWeight: 500}}>
              新增信用卡完成後，系統會試刷 NT$5 元，但不會從您的帳戶扣款。
            </div>
          </div>
        )}

        {smallDevice && (
          <PickerButton
            error={errors.selectedCreditCardId}
            style={{
              marginBottom: smallDevice ? 0 : 64,
            }}
            onClick={() => {
              modalRef.current.open(
                <SelectedCreditPicker
                  modalRef={modalRef}
                  values={values}
                  setValues={setValues}
                  myCreditCards={myCreditCards}
                  addCreditCard={addCreditCard}
                  installment={values.installment}
                />,
              );
            }}>
            {myCreditCards.find(c => c.id === values.selectedCreditCardId) ? (
              <Fragment>
                {
                  CREDIT_CARD_TYPE_PIC[
                    creditCardType(
                      myCreditCards.find(
                        c => c.id === values.selectedCreditCardId,
                      ).first_six,
                    )[0].type
                  ]
                }
                *{' '}
                {
                  myCreditCards.find(c => c.id === values.selectedCreditCardId)
                    .last_four
                }
              </Fragment>
            ) : (
              <div className="empty">請選擇信用卡</div>
            )}
          </PickerButton>
        )}
        <div
          id="add-credit-card-hidden-form"
          style={{display: 'none'}}
          dangerouslySetInnerHTML={{__html: addCreditCardForm}}
        />
      </div>

      <div className="step">
        <h2>3. 發票開立方式</h2>
        <div style={{marginBottom: smallDevice ? 0 : 64}}>
          <Row mobile={smallDevice}>
            <CheckButton
              checked={values.invoiceType === INVOICE_SUBTYPE.ecpay}
              onClick={() => {
                setValues(prev => ({
                  ...prev,
                  invoiceType: INVOICE_SUBTYPE.ecpay,
                }));
                setErrors(prev => ({
                  ...prev,
                  invoiceCarrier: null,
                  invoiceTax: null,
                }));
              }}
              style={{
                flex: 1,
                marginBottom: smallDevice ? 8 : 0,
                minHeight: 72,
              }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: smallDevice ? 'row' : 'column',
                  alignItems: smallDevice ? 'center' : 'flex-start',
                }}>
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: 16,
                  }}>
                  會員載具
                </div>
                <div
                  style={{
                    fontWeight: 300,
                    fontSize: 12,
                    color: Color.mainDark_70,
                    marginLeft: smallDevice ? 4 : 0,
                  }}>
                  {smallDevice
                    ? '( 中獎後以電子信箱通知 )'
                    : '中獎後以電子信箱通知'}
                </div>
              </div>
            </CheckButton>

            {!smallDevice && <div style={{width: 12}} />}

            <CheckButton
              checked={values.invoiceType === INVOICE_SUBTYPE.mobile}
              onClick={() => {
                setValues(prev => ({
                  ...prev,
                  invoiceType: INVOICE_SUBTYPE.mobile,
                }));
                setErrors(prev => ({
                  ...prev,
                  invoiceCarrier: null,
                  invoiceTax: null,
                }));
              }}
              style={{
                flex: 1,
                marginBottom: smallDevice ? 8 : 0,
                minHeight: 72,
              }}>
              手機條碼載具
            </CheckButton>

            {!smallDevice && <div style={{width: 12}} />}

            <CheckButton
              checked={values.invoiceType === INVOICE_SUBTYPE.b2b}
              onClick={() => {
                setValues(prev => ({
                  ...prev,
                  invoiceType: INVOICE_SUBTYPE.b2b,
                }));
                setErrors(prev => ({
                  ...prev,
                  invoiceCarrier: null,
                  invoiceTax: null,
                }));
              }}
              style={{
                flex: 1,
                marginBottom: smallDevice ? 8 : 0,
                minHeight: 72,
              }}>
              統一編號
            </CheckButton>
          </Row>
          <FlexRow style={{alignItems: 'flex-start', padding: '16px 0'}}>
            <CheckBox
              isOn={isAllowInvoiceLegal}
              onClick={() => {
                setIsAllowInvoiceLegal(prev => !prev);
              }}
            />
            <div style={{fontSize: 12, fontWeight: 500, paddingLeft: 5}}>
              {!!isAllowInvoiceLegalErrorMsg && (
                <div style={{color: Color.red}}>
                  {isAllowInvoiceLegalErrorMsg}
                </div>
              )}{' '}
              我同意辦理退貨時，由惇聚TUN GRP
              代為處理發票及銷貨退回證明單，以加速退貨退款作業。依統一發票使用辦法規定，個人發票一經開立，不得更改或改開公司戶發票。
              <a
                style={{
                  color: Color.mainDark,
                  textDecoration: 'underline',
                }}
                href="https://www.einvoice.nat.gov.tw/?CSRT=6201288656407980614">
                財政部電子發票流程說明
              </a>
            </div>
          </FlexRow>
        </div>
        {values.invoiceType === INVOICE_SUBTYPE.mobile && (
          <TextInput
            label="手機條碼"
            value={getValue('invoiceCarrier')}
            onChange={onValueChange('invoiceCarrier')}
            onBlur={onValidate('invoiceCarrier')}
            error={getError('invoiceCarrier')}
            style={{width: '100%', marginBottom: smallDevice ? 0 : 32}}
          />
        )}
        {values.invoiceType === INVOICE_SUBTYPE.b2b && (
          <TextInput
            label="統一編號"
            value={getValue('invoiceTax')}
            onChange={onValueChange('invoiceTax')}
            onBlur={onValidate('invoiceTax')}
            error={getError('invoiceTax')}
            style={{width: '100%', marginBottom: smallDevice ? 0 : 32}}
          />
        )}
      </div>

      <div className="step">
        <h2>4. 訂單備註</h2>

        <TextInput
          label="備註事項 (選填)"
          value={getValue('note')}
          onChange={onValueChange('note')}
          style={{width: '100%', marginBottom: smallDevice ? 0 : 64}}
        />
      </div>

      <div className="step">
        <h2>5. 訂單明細</h2>
        {itemsGroupByBrandId ? (
          <div style={{fontSize: smallDevice ? 12 : 14}}>
            {Object.keys(brandIdToBrandName).map((brandId, index) => (
              <div key={index} style={{position: 'relative'}}>
                {!smallDevice && itemsGroupByBrandId[brandId] && (
                  <FavStoreBlock
                    fetchFavStores={fetchFavStores}
                    mobile={smallDevice}
                    modalRef={modalRef}
                    store={favStores.find(
                      store => store.brand === brandIdToBrandName[brandId],
                    )}
                    brandId={brandId}
                    style={{
                      right: 0,
                      top: 0,
                      position: 'absolute',
                    }}
                  />
                )}
                {itemsGroupByBrandId[brandId] && (
                  <div style={{fontSize: 20, fontFamily: 'Gotham-Book'}}>
                    {
                      brandsDisplayArr.find(
                        b => b.key === brandIdToBrandName[brandId],
                      ).display
                    }
                  </div>
                )}
                {itemsGroupByBrandId[brandId]?.map((i, index) => {
                  const isPromote = !!i.variant.product.promotion;
                  const isMember = !!app.currentUser;
                  const priceTag = getPriceTag({
                    priceSet: {
                      price: i.variant.price * i.quantity,
                      member_price: i.variant.member_price * i.quantity,
                      promote_price: i.variant.promote_price * i.quantity,
                    },
                    isPromote,
                    isMember,
                    hasPrefix: true,
                    mobile: true,
                  });

                  const priceFlag = getPriceFlag({
                    product: i.variant.product,
                    isMember,
                    isPromote,
                  });

                  const memberDiscountRatio = getMemberDiscountRatio(
                    i.variant.product,
                  );

                  return (
                    <FlexRow
                      key={index}
                      style={{
                        margin: '24px 0',
                        justifyContent: 'space-between',
                      }}>
                      <FlexRow style={{alignItems: 'stretch', flex: 1}}>
                        <FixedRatioImage
                          image={i.variant.images[0]}
                          width={144}
                          ratio={1.25}
                          mode="cover"
                        />
                        <FlexCol
                          style={{
                            flex: 1,
                            justifyContent: 'space-between',
                            padding: '0 10px',
                          }}>
                          <FlexCol>
                            {priceFlag === 'member' &&
                              memberDiscountRatio !== 0 && (
                                <Tag>
                                  {L.d(
                                    MEMBERSHIP_LEVEL_DISPLAY[
                                      app.currentUser.vip_level
                                    ],
                                  )}{' '}
                                  -{memberDiscountRatio}%
                                </Tag>
                              )}
                            {priceFlag === 'promote' && (
                              <Tag>
                                {L.d(i.variant.product.promotion.title)} -
                                {100 - i.variant.product.promotion.ratio} %
                              </Tag>
                            )}
                            <strong className="product-name">
                              {L.d(i.variant.product.title)}
                            </strong>
                            <div
                              className="variant"
                              style={{marginTop: 12, color: Color.mainDark_70}}>
                              <small className="color">
                                {L.d(i.variant.colorDisplay)}
                              </small>
                              <small className="separate"> / </small>
                              <small className="size">
                                {L.d(i.variant.size)}
                              </small>
                            </div>
                            <small
                              className="quantity"
                              style={{color: Color.mainDark_70}}>
                              數量：{i.quantity}
                            </small>
                          </FlexCol>
                          <div
                            className="price-tag"
                            style={{textAlign: 'right'}}>
                            {priceTag}
                          </div>
                        </FlexCol>
                      </FlexRow>
                    </FlexRow>
                  );
                })}

                {itemsGroupByBrandId[brandId] && smallDevice && (
                  <FavStoreBlock
                    fetchFavStores={fetchFavStores}
                    mobile={smallDevice}
                    modalRef={modalRef}
                    store={favStores.find(
                      store => store.brand === brandIdToBrandName[brandId],
                    )}
                    brandId={brandId}
                    style={{flex: 1}}
                  />
                )}
                {itemsGroupByBrandId[brandId] && <SeparateLine />}
              </div>
            ))}
          </div>
        ) : (
          <div>未登入</div>
        )}
      </div>
    </Fragment>
  );

  let mainElem = null;

  if (smallDevice) {
    mainElem = (
      <MobileWrapper>
        <CustomNav middle={<div>結帳</div>} />
        <div className="steps">{stepsElem}</div>

        <CheckoutPanel
          loading={isCheckoutApiLoading}
          cart={app.cart}
          mobile={smallDevice}
          modal={modalRef}
          isCheckout
          onConfirm={onCheckout}
          values={values}
          setValues={setValues}
        />
      </MobileWrapper>
    );
  } else {
    mainElem = (
      <Fragment>
        <CustomNav
          left={
            <div
              onClick={() => {
                NavActions.navigate('/cart');
              }}
              style={{display: 'flex'}}>
              <ChevronLeft />
              <span style={{whiteSpace: 'nowrap'}}>返回購物車</span>
            </div>
          }
          middle={<LogoTungrp w={28} h={38} />}
        />
        <Wrapper>
          <div className="steps">{stepsElem}</div>

          <div className="actions">
            <CheckoutPanel
              loading={isCheckoutApiLoading}
              cart={app.cart}
              mobile={smallDevice}
              modal={modalRef}
              isCheckout
              onConfirm={onCheckout}
              values={values}
              setValues={setValues}
            />
            <Support mobile={smallDevice} style={{marginTop: 32}} />
          </div>
        </Wrapper>
      </Fragment>
    );
  }

  return (
    <>
      {mainElem}

      <Modal
        mobile={smallDevice}
        getInstance={inst => {
          modalRef.current = inst;
        }}
      />
    </>
  );
}
const Row = styled.div`
  display: flex;
  flex-direction: ${props => (props.mobile ? 'column' : 'row')};
  align-items: ${props => (props.mobile ? 'stretch' : 'center')};
`;

const Wrapper = styled.div`
  display: flex;
  padding: 40px 50px;
  color: #141414;
  background-color: white;

  & > .steps {
    flex: 1;
    margin-right: 80px;

    & > .step {
      margin: 30px 0;

      & .deprecated {
        color: ${Color.mainDark_70};
        text-decoration: line-through;
      }

      & > h2 {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.33;
        margin: 0 0 20px 0;
      }

      & > p {
        font-size: 12px;
        line-height: 1.67;
      }
    }
  }

  & > .actions {
    width: 400px;
    height: 100000%;
    position: sticky;
    top: 150px;
    right: 0;
  }
`;

const MobileWrapper = styled.div`
  background-color: rgba(20, 20, 20, 0.1);
  padding-bottom: 113px;

  & > .steps {
    & > .step {
      padding: 40px 20px;
      background-color: white;
      margin-bottom: 12px;

      & .deprecated {
        color: ${Color.mainDark_70};
        text-decoration: line-through;
      }

      & > h2 {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.33;
        margin: 0 0 20px 0;
      }

      & > p {
        font-size: 12px;
        line-height: 1.67;
      }
    }
  }
`;

const AddCardButton = styled.button`
  background-color: transparent;
  border: 1px solid ${props => (props.mobile ? '#cca75c' : 'transparent')};
  padding: ${props => (props.mobile ? '14px 4px' : '4px')};
  color: #cca75c;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
`;

const Tag = styled.div`
  color: #cca75c;
  max-width: 186px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default CheckoutPage;
