import {Actions} from '../Contexts/AppContext';
import {useEffect, useState} from 'react';

const useAdministrativeRegions = city => {
  const [countyOptions, setCountyOptions] = useState([]);
  const [townOptions, setTownOptions] = useState([]);
  const [towns, setTowns] = useState([]);

  useEffect(() => {
    const _fetch = async () => {
      const counties = await Actions.getAllCounties();
      const towns = await Actions.getAllTowns(
        counties.map(county => county.county_code),
      );
      setCountyOptions(
        counties.map(county => ({
          value: county.county_code,
          display: county.county_name,
        })),
      );
      setTowns(towns);
    };
    _fetch();
  }, []);

  useEffect(() => {
    if (city === '' || !city || towns.length <= 0) {
      return;
    }
    const townsOfCounty = towns[city];
    setTownOptions(
      townsOfCounty.map(town => ({
        value: town.towncode,
        display: town.townname,
      })),
    );
  }, [city, towns]);

  return {townOptions, countyOptions};
};

export default useAdministrativeRegions;
