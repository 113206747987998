import React from 'react';
import styled from 'styled-components';
import {ChevronLeft, Cross} from './SvgIcon';
import {Actions, Context} from '../Contexts/AppContext';
import {Color} from './Widget';

export default function InstallmentHintModalContent(props) {
  const {onClose, mobile} = props;

  return (
    <ModalContentWrapper mobile={mobile}>
      <div style={{position: 'absolute', right: 20, top: 20}} onClick={onClose}>
        <Cross style={{cursor: 'pointer'}} />
      </div>

      <h2>支援期數</h2>
      <p>
        滿 $5,000：可使用 3 期 0 利率
        <br />滿 $10,000：可使用 6 期 0 利率
      </p>

      <h2 style={{marginTop: 24}}>支援銀行</h2>
      <p>
        中國信託、國泰世華、台新、玉山、富邦、永豐、遠東、華南、日盛、樂天、安泰、聯邦、兆豐、台中商銀、上海銀行、凱基、匯豐、星展、新光、合庫、彰銀、一銀、元大、渣打銀行
      </p>
    </ModalContentWrapper>
  );
}

const ModalContentWrapper = styled.div`
  padding: ${props => (props.mobile ? '40px 24px' : '40px')};
  background-color: white;
  position: relative;
  width: 430px;
  max-width: calc(100vw - 40px);
  margin: auto;

  & > h2 {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: ${Color.mainDark};
    margin-bottom: 4px;
  }

  & > p {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${Color.mainDark_70};
  }
`;
