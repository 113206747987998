import styled from 'styled-components';
import {Color, FlexRow, FlexRowCenter} from '../../Components/Widget';
import * as SvgIcon from '../../Components/SvgIcon';
import React from 'react';

export const PickerContent = ({onClose, title, children}) => {
  // console.log(options);
  return (
    <StyledPickerContent>
      <SvgIcon.Cross className="close-btn" onClick={onClose} />
      <FlexRowCenter className="title">{title}</FlexRowCenter>
      {children}
    </StyledPickerContent>
  );
};

const StyledPickerContent = styled.div`
  width: calc(100% - 16px);
  min-height: 100px;
  background-color: ${Color.mainWhite};
  position: relative;
  margin: 8px;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  & > .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  & > .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 28px;
  }
  & > .option {
    width: 100%;
    margin-bottom: 8px;
  }
`;
