import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Actions, Context} from '../Contexts/AppContext';

export const useAddCreditCard = props => {
  const {returnPath, storeKey, restoreValuesFunc} = props;
  const [addCreditCardForm, setAddCreditCardForm] = useState('');

  const addCreditCard = useCallback(
    async storeValues => {
      const resp = await Actions.getBindCreditCardForm(returnPath);
      const form = resp.form_html;
      setAddCreditCardForm(form);
      window.localStorage.setItem(storeKey, JSON.stringify(storeValues));
    },
    [returnPath, storeKey],
  );

  const fetchMyCreditCards = async () => {
    return await Actions.getMyCreditCards();
  };

  useEffect(() => {
    if (!addCreditCardForm) {
      return;
    }
    const form = document
      .getElementById('add-credit-card-hidden-form')
      .querySelector('form[name="ecpay"]');
    form.submit();
  }, [addCreditCardForm, storeKey]);

  const restoreValues = useCallback(async () => {
    let _localDataStr = window.localStorage.getItem(storeKey);

    if (!_localDataStr) {
      return;
    }

    let _localData = null;
    let _tempData = Actions.getRuntimeCheckoutData();

    try {
      if (_localDataStr) {
        _localData = JSON.parse(_localDataStr);
        window.localStorage.removeItem(storeKey);
      }
    } catch (err) {
      throw err;
    }

    restoreValuesFunc(values => ({
      ...values,
      ...(_localData ? _localData : {}),
      ...(_tempData ? _tempData : {}),
    }));

    const creditCards = await fetchMyCreditCards();

    const isHasDefaultCard =
      creditCards.filter(card => card.is_primary).length > 0;

    if (!isHasDefaultCard) {
      await Actions.setDefaultCard(
        creditCards.sort((cardA, cardB) => cardA.id - cardB.id)[0].id,
      );
    }

    Actions.setRuntimeCheckoutData(null);
  }, [restoreValuesFunc, storeKey]);

  useEffect(() => {
    restoreValues();
  }, [restoreValues]);

  return {
    addCreditCard,
    addCreditCardForm: (
      <div
        id="add-credit-card-hidden-form"
        style={{display: 'none'}}
        dangerouslySetInnerHTML={{__html: addCreditCardForm}}
      />
    ),
  };
};

export const useCreditCards = () => {
  const app = useContext(Context);
  const [myCreditCards, setMyCreditCards] = useState([]);

  useEffect(() => {
    if (!app.currentUser) {
      return;
    }
    const fetchMyCreditCards = async () => {
      setMyCreditCards(await Actions.getMyCreditCards());
    };

    fetchMyCreditCards();
  }, [app.currentUser]);

  return {
    myCreditCards,
  };
};
