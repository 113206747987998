import React from 'react';
import styled from 'styled-components';

function Overlay(props) {
  const {getInstance, mobile} = props;
  const [origin, setOrigin] = React.useState({x: 0, y: 0});
  const [content, setContent] = React.useState(null);

  if (getInstance) {
    getInstance({
      open: ({domElem, content}) => {
        const origin = domElem.getBoundingClientRect();
        setOrigin(origin);
        setContent(content);
      },
      close: () => setContent(null),
    });
  }

  const visible = !!content;

  return (
    <>
      <Backdrop visible={visible} onClick={() => setContent(null)} />
      <OverlayWrapper visible={visible} origin={origin}>
        {content}
      </OverlayWrapper>
    </>
  );
}

const FullScreenWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: 300ms;
`;

const Backdrop = styled(FullScreenWrapper)`
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 12;
`;

const OverlayWrapper = styled.div`
  position: fixed;
  top: ${props => props.origin.bottom}px;
  left: ${props => props.origin.right}px;
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: ${props =>
    props.visible
      ? 'translateX(-100%) translateY(4px)'
      : 'translateX(-100%) translateY(-40px)'};
  transition: opacity 300ms 150ms, transform 200ms 150ms;
  z-index: 13;
`;

export default Overlay;
