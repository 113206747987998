import React from 'react';
import styled from 'styled-components';

function CheckButton(props) {
  const {
    checked,
    children,
    onClick,
    onMouseEnter = null,
    onMouseLeave = null,
    disabled = false,
    style = {},
  } = props;

  return (
    <Wrapper
      disabled={disabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={e => !disabled && onClick(e)}
      checked={checked}
      style={style}>
      <div className="mark">
        <div className="inner" />
      </div>

      {children}
    </Wrapper>
  );
}

const UNCHECK_COLOR = 'rgba(20, 20, 20, 0.1)';

// FIXME: `button` will not fire onMouseLeave when disabled, so we currently use `div`
const Wrapper = styled.div`
  padding: 14px 20px;
  border: 1px solid ${props => (props.checked ? '#141414' : UNCHECK_COLOR)};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  background-color: white;
  outline: none;
  color: ${props => (props.disabled ? UNCHECK_COLOR : '#141414')};

  & > .mark {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid ${props => (props.checked ? '#141414' : UNCHECK_COLOR)};
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > .inner {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: ${props => (props.checked ? '#141414' : UNCHECK_COLOR)};
      opacity: ${props => (props.checked ? 1 : 0)};
      transform: ${props => (props.checked ? 'scale(1)' : 'scale(0)')};
      transition: 200ms;
    }
  }
`;

export default CheckButton;
