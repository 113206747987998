import {useDimension} from '../../Hooks/AppHooks';
import {Color, FlexCol, FlexRow, UnderlineButton} from '../Widget';
import CheckButton from '../../Templates/Checkout/CheckButton';
import {CVS_TYPE_DATA, SHIPPING_TYPE} from '../../Domain/Order';
import SFIcon from '../../images/icon-logo-s-fexpress@3x.png';
import React from 'react';
import styled from 'styled-components';
import {Actions} from '../../Contexts/AppContext';
import * as ModalContent from '../../Templates/ProfilePage/AddrMgmt/ModalContent.addr';
import {
  openConfirmSetPrimaryAddressModal,
  openConfirmRemoveModal,
} from '../../Hooks/useAddressManager';

export const AddressCheckButton = props => {
  const {
    item,
    fetchAddress,
    overlayRef,
    initSelectedAddrId,
    setSelectedAddressId,
  } = props;
  const {mobile, tablet} = useDimension();
  const smallDevice = mobile || tablet;
  const anchorRef = React.useRef();

  const openMobileOverlayMenu = () => {
    overlayRef.current.open({
      domElem: anchorRef.current,
      content: (
        <OverlayMenu>
          {!item.primary && (
            <button
              onClick={() => {
                openConfirmSetPrimaryAddressModal({
                  item,
                  fetchAddress,
                  setSelectedAddressId,
                });
                overlayRef.current.close();
              }}>
              {item.shippingType === SHIPPING_TYPE.cvs
                ? '設為預設取件門市'
                : '設為預設宅配地址'}
            </button>
          )}
          <button
            onClick={async () => {
              Actions.setGlobalModalContent(
                item.shippingType === SHIPPING_TYPE.cvs ? (
                  <ModalContent.AddCvsStoreForm
                    fetchAddress={fetchAddress}
                    addr={item}
                  />
                ) : (
                  <ModalContent.AddAddressForm
                    fetchAddress={fetchAddress}
                    addr={item}
                  />
                ),
              );
              overlayRef.current.close();
            }}>
            修改
          </button>
          {!(item.id === initSelectedAddrId) && (
            <button
              disabled={item.id === initSelectedAddrId}
              onClick={async () => {
                openConfirmRemoveModal({id: item.id, fetchAddress});
                overlayRef.current.close();
              }}>
              刪除
            </button>
          )}
        </OverlayMenu>
      ),
    });
  };

  if (smallDevice) {
    return (
      <MobileWrapper>
        {item.primary && (
          <FlexRow className="default-tag">
            {item.shippingType === SHIPPING_TYPE.cvs ? '預設門市' : '預設地址'}
          </FlexRow>
        )}
        <FlexCol className="content">
          <CheckButton {...props} style={{border: 'none', padding: 0}}>
            <FlexRow className="customer-info">
              <FlexRow>
                <strong>{item.receiverName}</strong>
                <small>{item.receiverPhone}</small>
              </FlexRow>
              <UnderlineButton
                ref={anchorRef}
                onClick={e => {
                  e.stopPropagation();
                  openMobileOverlayMenu();
                }}>
                編輯
              </UnderlineButton>
            </FlexRow>
          </CheckButton>
          <div className="separate" />
          <FlexRow>
            {item.shippingType === SHIPPING_TYPE.home && (
              <img className="icon" src={SFIcon} alt="sf-icon" />
            )}
            {item.shippingType === SHIPPING_TYPE.cvs && (
              <img
                className="icon"
                src={CVS_TYPE_DATA[item.storeType].iconPath}
                alt="cvs icon"
              />
            )}
            {item.shippingType === SHIPPING_TYPE.home && (
              <small>
                {item.city}
                {item.district}
                {item.detail}
              </small>
            )}

            {item.shippingType === SHIPPING_TYPE.cvs && (
              <FlexCol>
                <strong>{item.storeName}</strong>
                <small>{item.storeAddr}</small>
              </FlexCol>
            )}
          </FlexRow>
        </FlexCol>
      </MobileWrapper>
    );
  }

  return (
    <CheckButton {...props}>
      <DesktopWrapper>
        <FlexCol className="customer-info">
          <strong>{item.receiverName}</strong>
          <small>{item.receiverPhone}</small>
        </FlexCol>
        <div className="separate" />
        <FlexRow className="address-info">
          {item.shippingType === SHIPPING_TYPE.home && (
            <img className="icon" src={SFIcon} alt="sf-icon" />
          )}
          {item.shippingType === SHIPPING_TYPE.cvs && (
            <img
              className="icon"
              src={CVS_TYPE_DATA[item.storeType].iconPath}
              alt="cvs icon"
            />
          )}
          {item.shippingType === SHIPPING_TYPE.home && (
            <small>
              {item.city}
              {item.district}
              {item.detail}
            </small>
          )}

          {item.shippingType === SHIPPING_TYPE.cvs && (
            <FlexCol>
              <strong>{item.storeName}</strong>
              <small>{item.storeAddr}</small>
            </FlexCol>
          )}
        </FlexRow>
        <div className="filler" />
        <FlexRow className="action-area">
          <div className={`default-tag ${item.primary ? '' : 'hidden'}`}>
            {item.shippingType === SHIPPING_TYPE.cvs ? '預設門市' : '預設地址'}
          </div>
          <UnderlineButton
            ref={anchorRef}
            onClick={e => {
              e.stopPropagation();
              openMobileOverlayMenu();
            }}>
            編輯
          </UnderlineButton>
          <span className="action-separate">|</span>
          <UnderlineButton
            disabled={item.id === initSelectedAddrId}
            onClick={e => {
              e.stopPropagation();
              openConfirmRemoveModal({id: item.id, fetchAddress});
            }}>
            刪除
          </UnderlineButton>
        </FlexRow>
      </DesktopWrapper>
    </CheckButton>
  );
};

const MobileWrapper = styled(FlexCol)`
  border: 1px solid ${Color.mainDark};
  margin: 10px 0;
  & > .default-tag {
    background-color: ${Color.mainDark_10};
    justify-content: center;
    padding: 4px 0;
    font-size: 14px;
  }
  & > .content {
    padding: 16px 20px;
    & .customer-info {
      flex: 1;
      justify-content: space-between;
      & strong {
        margin-right: 8px;
        font-size: 12px;
        font-weight: 500;
      }
      & small {
        font-size: 12px;
        color: ${Color.mainDark_70};
      }
    }
    & > .separate {
      flex: 1;
      border-bottom: 1px solid ${Color.mainDark_10};
      margin: 16px 0;
    }
    & .icon {
      width: 28px;
      height: 28px;
      margin-right: 14px;
    }
  }
`;

const DesktopWrapper = styled(FlexRow)`
  width: 760px;
  align-items: stretch;
  & > .customer-info {
    width: 100px;
    & > strong {
      font-size: 14px;
      font-weight: 500;
    }
    & > small {
      font-size: 14px;
      color: ${Color.mainDark_70};
    }
  }
  & > .separate {
    width: 1px;
    background-color: ${Color.mainDark_10};
    margin-left: 46px;
    margin-right: 20px;
  }
  & > .address-info {
    & > .icon {
      width: 28px;
      height: 28px;
      margin-right: 14px;
    }
    & small {
      font-size: 14px;
      font-weight: 300;
    }
  }

  & .filler {
    flex: 1;
  }
  & > .action-area {
    & > .hidden {
      visibility: hidden;
    }
    & > .default-tag {
      background-color: ${Color.mainDark_10};
      padding: 4px 8px;
      font-size: 14px;
      font-weight: 500;
      margin-right: 32px;
    }
    & > .action-separate {
      margin: 0 10px;
    }
  }
`;

const OverlayMenu = styled.div`
  padding: 20px;
  background-color: white;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > button {
    outline: none;
    border: none;
    background-color: transparent;
    margin-bottom: 15px;
    white-space: nowrap;
  }

  & > button:last-child {
    margin-bottom: 0;
  }
`;
