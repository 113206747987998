import {Color, FlexCol, FlexRow} from '../../Components/Widget';
import * as SvgIcon from '../../Components/SvgIcon';
import React from 'react';
import styled from 'styled-components';

export const PickerButton = ({children, onClick, style, error}) => {
  return (
    <FlexCol>
      <StyledPickerButton error={error} onClick={onClick} style={style}>
        <FlexRow>{children}</FlexRow>
        <SvgIcon.DropDown />
      </StyledPickerButton>
      {error && <span style={{marginTop: 8, color: Color.red}}>{error}</span>}
    </FlexCol>
  );
};

const StyledPickerButton = styled.div`
  flex: 1;
  height: 52px;
  padding: 10px 20px;
  background-color: ${Color.mainDark_03};
  display: flex;
  justify-content: space-between;
  border-bottom: ${({error}) => (error ? `1px solid ${Color.red}` : 'none')};
  & .empty {
    color: ${Color.mainDark_70};
  }
`;
