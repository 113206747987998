import React, {useContext, useEffect, useRef} from 'react';
import {Actions, Context} from '../../Contexts/AppContext';
import * as ModalContent from '../ProfilePage/ModalContent.favStore';
import {
  Color,
  FlexCol,
  FlexRow,
  FlexRowCenter,
  UnderlineButton,
} from '../../Components/Widget';
import {Info} from '../../Components/SvgIcon';
import * as L from '../../Utils/Lang';
import {Tooltip} from 'antd';

const FavStoreBlock = props => {
  const {store, modalRef, mobile, fetchFavStores, style = {}} = props;
  const app = useContext(Context);

  const onSelectStore = (storeId = '') => {
    modalRef.current.open(
      <ModalContent.SelectStoreForm
        storeId={storeId}
        brand={store.brand.toLocaleLowerCase()}
        allStoreData={app.allStoreData}
        modalRef={modalRef}
        mobile={mobile}
        fetchFavStores={fetchFavStores}
      />,
    );
  };

  return (
    <FlexCol
      style={{
        padding: 20,
        backgroundColor: Color.mainDark_03,
        ...style,
      }}>
      <FlexRow style={{justifyContent: 'space-between'}}>
        <FlexRowCenter>
          <strong>指定服務門市</strong>
          <Tooltip
            placement="bottom"
            title="若您需要維修、保養、線上諮詢...等協助，將由此門市替您服務。">
            <Info />
          </Tooltip>
        </FlexRowCenter>
        <UnderlineButton
          onClick={() => {
            onSelectStore(store.storeId);
          }}>
          更換門市
        </UnderlineButton>
      </FlexRow>
      <FlexRow>
        <FlexCol style={{color: Color.mainDark_70}}>
          <small>{L.d(store.storeName)}</small>
          <small>{L.d(store.storeAddr)}</small>
        </FlexCol>
      </FlexRow>
    </FlexCol>
  );
};

export default FavStoreBlock;
