import RectButton, {BUTTON_SKIN} from '../RectButton';
import {Actions} from '../../Contexts/AppContext';
import * as ModalContent from '../../Templates/ProfilePage/AddrMgmt/ModalContent.addr';
import {Color} from '../Widget';
import React from 'react';

export const AddCvsStoreButton = props => {
  const {fetchAddress, style = {}} = props;
  return (
    <RectButton
      text="+ 新增取件門市"
      skin={BUTTON_SKIN.CRYSTAL}
      onClick={() => {
        Actions.setGlobalModalContent(
          <ModalContent.AddCvsStoreForm fetchAddress={fetchAddress} />,
        );
      }}
      style={{
        borderColor: Color.gold,
        color: Color.gold,
        ...style,
      }}
    />
  );
};
