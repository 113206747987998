import React from 'react';
import styled from 'styled-components';
import {useFocus} from '../Hooks/AppHooks';
import {DropDown} from './SvgIcon';
import * as L from '../Utils/Lang';

function Select(props) {
  const {
    label,
    error,
    options,
    disabled = false,
    style = {},
    ...inputProps
  } = props;
  const inputRef = React.useRef();
  const {focus} = useFocus(inputRef);

  return (
    <Wrapper
      focus={focus}
      error={error}
      style={style}
      onClick={() => inputRef.current.focus()}>
      <label>{label}</label>
      <div className="input">
        <select disabled={disabled} ref={inputRef} {...inputProps}>
          <option value="" disabled={true}>
            請選擇
          </option>
          {options.map(({value, display, disabled = false}) => (
            <option value={value} key={value} disabled={disabled}>
              {L.d(display)}
            </option>
          ))}
        </select>
        <DropDown fill={focus ? '#141414' : 'rgba(20,20,20,0.1)'} />
      </div>
      <div className="error">{error}</div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;

  & > label {
    display: block;
    color: ${props => (props.focus ? '#141414' : 'rgba(20, 20, 20, 0.7)')};
    transition: 300ms;
    margin: 0 0 4px;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.67;
  }

  & > .input {
    width: 100%;
    margin: 4px 0;
    padding-bottom: 12px;
    border-bottom: ${props =>
      props.error
        ? '1px solid #e22828'
        : props.focus
        ? '1px solid #141414'
        : '1px solid rgba(20, 20, 20, 0.1)'};
    transition: 300ms;
    display: flex;
    align-items: center;

    & > select {
      flex: 1;
      background-color: #fff;
      appearance: none;
      outline: none;
      border: none;
    }
  }

  & > .error {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.67;
    color: #e22828;
    max-height: ${props => (props.error ? 100 : 0)}px;
    opacity: ${props => (props.error ? 1 : 0)};
    transition: 300ms;
  }
`;

export default Select;
