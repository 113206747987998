import React, {useState} from 'react';
import {PickerContent} from './PickerContent';
import {Color, FlexRow, UnderlineButton} from '../../Components/Widget';
import CheckButton from './CheckButton';
import {CREDIT_CARD_TYPE_PIC} from '../../Domain/Order';
import creditCardType from 'credit-card-type';
import ModalContentInstallmentHint from '../../Components/ModalContent.installmentHint';
import styled from 'styled-components';

export const SelectedCreditPicker = props => {
  const {
    modalRef,
    myCreditCards,
    setValues,
    values,
    addCreditCard,
    installment,
  } = props;
  const [
    currentSelectedCreditCardId,
    setCurrentSelectedCreditCardId,
  ] = useState(values.selectedCreditCardId);

  return (
    <PickerContent title="選擇付款信用卡" onClose={modalRef.current.close}>
      {myCreditCards.map((opt, idx) => (
        <FlexRow className="option" key={idx}>
          <CheckButton
            style={{
              width: '100%',
              border: 'none',
              borderBottom: `1px solid ${Color.mainDark_10}`,
            }}
            checked={opt.id === currentSelectedCreditCardId}
            onClick={() => {
              setCurrentSelectedCreditCardId(opt.id);
              setValues({...values, selectedCreditCardId: opt.id});
            }}>
            {CREDIT_CARD_TYPE_PIC[creditCardType(opt.first_six)[0].type]}*{' '}
            {opt.last_four}
          </CheckButton>
        </FlexRow>
      ))}
      <div style={{marginBottom: 20}}>
        <AddCardButton
          style={{
            textAlign: 'left',
            padding: '12px 0',
            width: '100%',
            borderBottom:
              installment && installment !== 0
                ? `1px solid ${Color.mainDark_10}`
                : 'none',
          }}
          onClick={addCreditCard}>
          + 新增信用卡
        </AddCardButton>
        <div style={{paddingLeft: 15, fontSize: 12, fontWeight: 500}}>
          新增信用卡完成後，系統會試刷 NT$5 元，但不會從您的帳戶扣款。
        </div>
      </div>
      {installment && installment !== 0 ? (
        <UnderlineButton
          onClick={() => {
            modalRef.current.open(
              <ModalContentInstallmentHint
                onConfirm={() => 0}
                onClose={modalRef.current.close}
              />,
            );
          }}>
          點擊了解支援期數 / 銀行
        </UnderlineButton>
      ) : null}
    </PickerContent>
  );
};

const AddCardButton = styled.button`
  background-color: transparent;
  border: 1px solid ${props => (props.mobile ? '#cca75c' : 'transparent')};
  padding: ${props => (props.mobile ? '14px 4px' : '4px')};
  color: #cca75c;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
`;
