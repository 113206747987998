import React, {useContext} from 'react';
import * as AppContext from '../Contexts/AppContext';
import {Context} from '../Contexts/AppContext';

const useFavStores = () => {
  const app = useContext(Context);
  const [favStores, setFavStores] = React.useState([]);

  const fetchFavStores = React.useCallback(async () => {
    try {
      if (!app.currentUser) {
        return;
      }

      const resp = await AppContext.Actions.fetchFavStores();
      setFavStores(resp);
      return resp;
    } catch (ex) {
      console.warn(ex);
    }
  }, [app.currentUser]);

  React.useEffect(() => {
    fetchFavStores();
  }, [fetchFavStores]);

  return {favStores, fetchFavStores};
};

export default useFavStores;
