import React, {useContext, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import RectButton, {BUTTON_SKIN} from '../../../Components/RectButton';
import Select from '../../../Components/Select';
import {Actions, Context} from '../../../Contexts/AppContext';
import {
  getPhoneValidator,
  getRequiredValidator,
  validateForm,
} from '../../../Utils/validateUtil';
import TextInput from '../../../Components/TextInput';
import {CheckBox, Color, FlexCol, FlexRow} from '../../../Components/Widget';
import {CVS_TYPE_DATA, SHIPPING_TYPE} from '../../../Domain/Order';
import * as L from '../../../Utils/Lang';
import useAdministrativeRegions from '../../../Hooks/useAdministrativeRegions';
import {openSelectCvsWindow} from '../../../Hooks/useAddressManager';
import {useDimension} from '../../../Hooks/AppHooks';

export const AddAddressForm = props => {
  const {fetchAddress, addr} = props;
  const {mobile, tablet} = useDimension();
  const smallDevice = mobile || tablet;
  const isEdit = useMemo(() => !!addr, [addr]);
  const [values, setValues] = useState({
    city: '',
    town: '',
    address: '',
    receiverName: '',
    receiverPhone: '',
    isDefault: false,
  });
  const [errors, setErrors] = useState({
    city: null,
    town: null,
    address: null,
    receiverName: null,
    receiverPhone: null,
    isDefault: null,
  });
  const validators = useMemo(
    () => ({
      city: getRequiredValidator(),
      town: getRequiredValidator(),
      address: getRequiredValidator(),
      receiverName: getRequiredValidator(),
      receiverPhone: getPhoneValidator(),
    }),
    [],
  );

  const {townOptions, countyOptions} = useAdministrativeRegions(values.city);

  useEffect(() => {
    if (!isEdit || countyOptions.length <= 0) {
      return;
    }
    setValues(prev => ({
      ...prev,
      address: addr.detail,
      city: countyOptions.find(opt => opt.display === addr.city)?.value || '',
      isDefault: addr.primary,
      town: townOptions.find(opt => opt.display === addr.district)?.value || '',
      receiverName: addr.receiverName,
      receiverPhone: addr.receiverPhone,
    }));
  }, [addr, countyOptions, isEdit, townOptions]);

  const onSubmit = async () => {
    const isAllPass = validateForm({values, errors, setErrors, validators});
    if (!isAllPass) {
      return;
    }

    const _post = {
      id: isEdit ? addr.id : undefined,
      receiver_name: values.receiverName,
      receiver_phone: values.receiverPhone,
      city:
        countyOptions.find(county => county.value === values.city)?.display ||
        '',
      district:
        townOptions.find(town => town.value === values.town)?.display || '',
      detail: values.address,
      address_type: SHIPPING_TYPE.home,
      default: values.isDefault,
    };

    let isSuccess;

    if (isEdit) {
      isSuccess = await Actions.updateAddress(_post);
    } else {
      isSuccess = await Actions.createAddress(_post);
    }

    if (!isSuccess) {
      if (isEdit) {
        alert('修改地址失敗');
      } else {
        alert('新增地址失敗');
      }
      return;
    }

    await fetchAddress();
    Actions.setGlobalModalContent(null);
  };

  const onValidate = field => e => {
    setErrors({
      ...errors,
      [field]: validators[field].passRequest(values[field]),
    });
  };

  const onValueChange = field => e => {
    setValues({...values, [field]: e.target.value});
  };

  return (
    <ModalContentWrapper mobile={smallDevice}>
      <div className="top">
        <FlexRow>
          <div
            style={{
              fontWeight: 500,
              fontSize: '24px',
              margin: '20px 0',
            }}>
            {isEdit ? '修改宅配地址' : '新增宅配地址'}
          </div>
        </FlexRow>
        <div
          style={{
            display: 'flex',
            flexDirection: smallDevice ? 'column' : 'row',
          }}>
          <TextInput
            label="收件人姓名"
            value={values.receiverName}
            onChange={onValueChange('receiverName')}
            onBlur={onValidate('receiverName')}
            error={errors.receiverName}
            style={{
              flex: 1,
              margin: smallDevice ? '0 0 12px 0' : '0 12px 24px 0',
            }}
          />

          <TextInput
            label="收件人手機"
            maxLength={10}
            value={values.receiverPhone}
            onChange={onValueChange('receiverPhone')}
            onBlur={onValidate('receiverPhone')}
            error={errors.receiverPhone}
            style={{
              flex: 1,
              margin: smallDevice ? '0 0 12px 0' : '0 0 24px 12px',
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: smallDevice ? 'column' : 'row',
          }}>
          <Select
            label="縣市"
            options={countyOptions}
            value={values.city}
            onChange={onValueChange('city')}
            onBlur={onValidate('city')}
            error={errors.city}
            style={{
              flex: 1,
              margin: smallDevice ? '0 0 12px 0' : '0 12px 12px 0',
            }}
          />

          <Select
            disabled={values.city === ''}
            label="鄉鎮區"
            options={townOptions}
            value={values.town}
            onChange={onValueChange('town')}
            onBlur={onValidate('town')}
            error={errors.town}
            style={{
              flex: 1,
              margin: smallDevice ? '0 0 12px 0' : '0 0 12px 12px',
            }}
          />
        </div>

        <TextInput
          label="地址"
          value={values.address}
          onChange={onValueChange('address')}
          onBlur={onValidate('address')}
          error={errors.address}
          style={{flex: 1, marginBottom: 24}}
        />

        <FlexRow>
          <CheckBox
            isOn={values.isDefault}
            onClick={() => {
              setValues(prev => ({...prev, isDefault: !values.isDefault}));
            }}
            size={16}
          />
          <div
            onClick={() => {
              setValues(prev => ({...prev, isDefault: !values.isDefault}));
            }}
            style={{fontSize: 14, fontWeight: 500, marginLeft: 8}}>
            設為預設宅配地址
          </div>
        </FlexRow>
      </div>
      <div className="separate" />
      <div className="bottom">
        <FlexRow
          style={{
            justifyContent: 'flex-end',
            flexDirection: smallDevice ? 'column' : 'row',
          }}>
          <RectButton
            style={{
              margin: smallDevice ? '0 0 12px 0' : '0 12px 0 0',
              padding: '14px 64px',
              border: 'none',
            }}
            text="取消"
            skin={BUTTON_SKIN.DEFAULT}
            onClick={() => {
              Actions.setGlobalModalContent(null);
            }}
          />
          <RectButton
            style={{padding: '14px 64px'}}
            text="確定"
            skin={BUTTON_SKIN.PRIMARY}
            onClick={onSubmit}
          />
        </FlexRow>
      </div>
    </ModalContentWrapper>
  );
};

export const AddCvsStoreForm = props => {
  const {fetchAddress, addr} = props;
  const app = useContext(Context);
  const isEdit = useMemo(() => !!addr, [addr]);
  const {mobile, tablet} = useDimension();
  const smallDevice = mobile || tablet;
  const [values, setValues] = useState({
    id: null,
    storeId: '',
    storeName: '',
    storeAddress: '',
    cvsStoreType: '',
    receiverName: '',
    receiverPhone: '',
    isDefault: false,
  });
  const [errors, setErrors] = useState({
    cvsStoreType: null,
    receiverName: null,
    receiverPhone: null,
    isDefault: null,
  });
  const validators = useMemo(
    () => ({
      cvsStoreType: getRequiredValidator(),
      receiverName: getRequiredValidator(),
      receiverPhone: getPhoneValidator(),
    }),
    [],
  );

  useEffect(() => {
    if (!isEdit) {
      return;
    }
    setValues(prev => ({
      ...prev,
      id: addr.id,
      isDefault: addr.primary,
      receiverName: addr.receiverName,
      storeId: addr.storeId,
      cvsStoreType: addr.storeType,
      receiverPhone: addr.receiverPhone,
      storeAddress: addr.storeAddr,
      storeName: addr.storeName,
    }));
  }, [addr, isEdit]);

  const onSubmit = async () => {
    const isAllPass = validateForm({values, errors, setErrors, validators});
    if (!isAllPass) {
      return;
    }

    const _post = {
      id: values.id || undefined,
      receiver_name: values.receiverName,
      receiver_phone: values.receiverPhone,
      address_type: SHIPPING_TYPE.cvs,
      store_name: values.storeName,
      store_id: values.storeId,
      store_type: values.cvsStoreType.toLocaleLowerCase(),
      detail: values.storeAddress,
      default: values.isDefault,
    };

    let isSuccess;

    if (values.id) {
      isSuccess = await Actions.updateAddress(_post);
    } else {
      isSuccess = await Actions.createAddress(_post);
    }

    if (!isSuccess) {
      if (values.id) {
        alert('修改門市失敗');
      } else {
        alert('新增門市失敗');
      }
      return;
    }

    await fetchAddress();
    Actions.setGlobalModalContent(null);
  };

  useEffect(() => {
    if (!app.runtimeProfileCvsData) {
      return;
    }
    setValues({
      id: app.runtimeProfileCvsData.id || null,
      storeId: app.runtimeProfileCvsData.storeId,
      storeName: app.runtimeProfileCvsData.storeName,
      storeAddress: app.runtimeProfileCvsData.storeAddress,
      cvsStoreType: app.runtimeProfileCvsData.cvsStoreType,
      receiverName: app.runtimeProfileCvsData.receiverName,
      receiverPhone: app.runtimeProfileCvsData.receiverPhone,
      isDefault: app.runtimeProfileCvsData.isDefault,
    });

    Actions.setRuntimeProfileCvsData(null);
  }, [app.runtimeProfileCvsData]);

  const onValueChange = field => e => {
    if (field === 'cvsStoreType') {
      setValues({
        ...values,
        cvsStoreType: e.target.value,
        storeName: '',
        storeId: '',
        storeAddress: '',
      });
    } else {
      setValues({...values, [field]: e.target.value});
    }
  };

  const onValidate = field => e => {
    setErrors({
      ...errors,
      [field]: validators[field].passRequest(values[field]),
    });
  };

  const cvsStoreTypeOpts = useMemo(
    () => [
      {
        value: CVS_TYPE_DATA.FAMIC2C.key,
        display: L.s(CVS_TYPE_DATA.FAMIC2C.display_key),
      },
      {
        value: CVS_TYPE_DATA.UNIMARTC2C.key,
        display: L.s(CVS_TYPE_DATA.UNIMARTC2C.display_key),
      },
      {
        value: CVS_TYPE_DATA.HILIFEC2C.key,
        display: L.s(CVS_TYPE_DATA.HILIFEC2C.display_key),
      },
    ],
    [],
  );

  return (
    <ModalContentWrapper mobile={smallDevice}>
      <div className="top">
        <FlexRow>
          <div
            style={{
              fontWeight: 500,
              fontSize: '24px',
              margin: '20px 0',
            }}>
            {values.id ? '修改取件門市' : '新增取件門市'}
          </div>
        </FlexRow>

        <div
          style={{
            display: 'flex',
            flexDirection: smallDevice ? 'column' : 'row',
            alignItems: 'stretch',
          }}>
          <FlexCol
            style={{
              flex: 1,
              margin: smallDevice ? '0 0 12px 0' : '0 6px 24px 0',
              position: 'relative',
            }}>
            <TextInput
              label="收件人姓名"
              value={values.receiverName}
              onChange={onValueChange('receiverName')}
              onBlur={onValidate('receiverName')}
              error={errors.receiverName}
            />
            <div
              style={{
                fontWeight: 500,
                fontSize: 12,
                color: Color.gold,
                position: 'absolute',
                top: '100%',
              }}>
              請填寫真實姓名，取件時須核對身分證件
            </div>
          </FlexCol>

          <TextInput
            label="收件人手機"
            maxLength={10}
            value={values.receiverPhone}
            onChange={onValueChange('receiverPhone')}
            onBlur={onValidate('receiverPhone')}
            error={errors.receiverPhone}
            style={{
              flex: 1,
              margin: smallDevice ? '0 0 12px 0' : '0 0 24px 6px',
            }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: smallDevice ? 'column' : 'row',
          }}>
          <Select
            label="取件超商"
            options={cvsStoreTypeOpts}
            value={values.cvsStoreType}
            onChange={onValueChange('cvsStoreType')}
            onBlur={onValidate('cvsStoreType')}
            error={errors.cvsStoreType}
            style={{flex: 1, marginBottom: 12, marginRight: 6}}
          />

          {values.storeId !== '' ? (
            <CvsStoreBlock>
              <div className="icon">
                <img
                  src={CVS_TYPE_DATA[values.cvsStoreType].iconPath}
                  alt="cvs icon"
                />
              </div>
              <div className="detail">
                <div className="title">{values.storeName}</div>
                <div className="address">{values.storeAddress}</div>
              </div>
              <div
                className="action-btn"
                onClick={() => {
                  openSelectCvsWindow({
                    values,
                    isFromModal: true,
                    points: app.points,
                    isUsePoints: app.isUsePoints,
                  });
                }}>
                變更
              </div>
            </CvsStoreBlock>
          ) : (
            <div
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                margin: smallDevice ? '12px 0 12px 0' : '0 0 0 6px',
              }}>
              <RectButton
                style={{flex: smallDevice ? 1 : 'unset'}}
                isDisabled={values.cvsStoreType === ''}
                text="選擇門市"
                skin={BUTTON_SKIN.PRIMARY}
                onClick={() => {
                  openSelectCvsWindow({
                    values,
                    isFromModal: true,
                    points: app.points,
                    isUsePoints: app.isUsePoints,
                  });
                }}
              />
            </div>
          )}
        </div>

        <FlexRow>
          <CheckBox
            isOn={values.isDefault}
            onClick={() => {
              setValues(prev => ({...prev, isDefault: !values.isDefault}));
            }}
            size={16}
          />
          <div
            onClick={() => {
              setValues(prev => ({...prev, isDefault: !values.isDefault}));
            }}
            style={{
              fontSize: 14,
              fontWeight: 500,
              marginLeft: 8,
            }}>
            設為預設取件門市
          </div>
        </FlexRow>
      </div>
      <div className="separate" />
      <div className="bottom">
        <FlexRow
          style={{
            justifyContent: 'flex-end',
            flexDirection: smallDevice ? 'column' : 'row',
          }}>
          <RectButton
            style={{
              margin: smallDevice ? '0 0 12px 0' : '0 12px 0 0',
              padding: '14px 64px',
              border: 'none',
            }}
            text="取消"
            skin={BUTTON_SKIN.DEFAULT}
            onClick={() => {
              Actions.setGlobalModalContent(null);
            }}
          />
          <RectButton
            style={{padding: '14px 64px'}}
            text="確定"
            skin={BUTTON_SKIN.PRIMARY}
            onClick={onSubmit}
          />
        </FlexRow>
      </div>
    </ModalContentWrapper>
  );
};

const ModalContentWrapper = styled.div`
  background-color: white;
  position: relative;
  width: ${props => (props.mobile ? 'unset' : '888px')};
  overflow-y: auto;

  & > .top {
    padding: ${props =>
      props.mobile ? '20px 20px 0 20px' : '20px 50px 0 50px'};
  }

  & > .bottom {
    padding: ${props =>
      props.mobile ? '0px 20px 20px 20px' : '0 50px 20px 50px'};
  }

  & > .separate {
    height: 1px;
    background-color: ${Color.mainDark_10};
    flex: 1;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  & > h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
  }

  & > p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    color: #ccc;
    margin-bottom: 32px;
  }
`;

const CvsStoreBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  flex: 1;
  margin-left: 6px;

  & > .icon {
    width: 32px;
    height: 32px;

    & > img {
      width: 100%;
      height: 100%;
    }
  }
  & > .detail {
    margin-left: 12px;
    margin-right: 20px;

    & > .title {
      color: #141414;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
    }

    & .address {
      font-size: 12px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: rgba(20, 20, 20, 0.7);
    }
  }
  & > .action-btn {
    white-space: nowrap;
    font-size: 14px;
    text-decoration: underline;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: right;
    color: #141414;
    cursor: pointer;
  }

  background-color: rgba(20, 20, 20, 0.03);
`;
