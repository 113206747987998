import styled from 'styled-components';
import {
  Color,
  FlexCol,
  FlexRow,
  UnderlineButton,
} from '../../../../Components/Widget';
import {CVS_TYPE_DATA, SHIPPING_TYPE} from '../../../../Domain/Order';
import SFIcon from '../../../../images/icon-logo-s-fexpress@3x.png';
import {Actions} from '../../../../Contexts/AppContext';
import EditAddress from '../../../../Components/Modal/EditAddress';
import React from 'react';
import {useDimension} from '../../../../Hooks/AppHooks';

export const AddressItem = props => {
  const {
    item,
    fetchAddress,
    initSelectedAddrId,
    isEnableEdit = true,
    addrs,
    AddCvsStoreButton,
    AddAddressButton,
    setSelectedAddressId,
    editButtonWording = '變更收件地址',
  } = props;
  const {mobile, tablet} = useDimension();
  const smallDevice = mobile || tablet;

  if (smallDevice) {
    return (
      <Wrapper style={{flexDirection: 'column'}} className="address-item">
        <FlexRow style={{justifyContent: 'space-between'}}>
          <FlexCol style={{flexDirection: 'row'}} className="customer-info">
            <strong style={{marginRight: 8}}>{item.receiverName}</strong>
            <small>{item.receiverPhone}</small>
          </FlexCol>

          {isEnableEdit && (
            <UnderlineButton
              disabled={addrs.length <= 0}
              onClick={() => {
                Actions.setGlobalModalContent(
                  <EditAddress
                    fetchAddress={fetchAddress}
                    addrs={addrs}
                    AddCvsStoreButton={AddCvsStoreButton}
                    AddAddressButton={AddAddressButton}
                    initSelectedAddrId={initSelectedAddrId}
                    setSelectedAddressId={setSelectedAddressId}
                    item={item}
                  />,
                );
              }}>
              變更
            </UnderlineButton>
          )}
        </FlexRow>

        <div
          style={{
            width: '100%',
            height: 1,
            backgroundColor: Color.mainDark_10,
            margin: '16px 0',
          }}
        />
        <FlexRow className="address-info">
          {item.shippingType === SHIPPING_TYPE.home && (
            <img className="icon" src={SFIcon} alt="sf-icon" />
          )}
          {item.shippingType === SHIPPING_TYPE.cvs && (
            <img
              className="icon"
              src={CVS_TYPE_DATA[item.storeType].iconPath}
              alt="cvs icon"
            />
          )}
          {item.shippingType === SHIPPING_TYPE.home && (
            <small>
              {item.city}
              {item.district}
              {item.detail || item.address}
            </small>
          )}

          {item.shippingType === SHIPPING_TYPE.cvs && (
            <FlexCol>
              <strong>{item.storeName}</strong>
              <small>{item.storeAddr}</small>
            </FlexCol>
          )}
        </FlexRow>
      </Wrapper>
    );
  }

  return (
    <Wrapper className="address-item">
      <FlexCol className="customer-info">
        <strong>{item.receiverName}</strong>
        <small>{item.receiverPhone}</small>
      </FlexCol>
      <div className="separate" />
      <FlexRow className="address-info">
        {item.shippingType === SHIPPING_TYPE.home && (
          <img className="icon" src={SFIcon} alt="sf-icon" />
        )}
        {item.shippingType === SHIPPING_TYPE.cvs && (
          <img
            className="icon"
            src={CVS_TYPE_DATA[item.storeType].iconPath}
            alt="cvs icon"
          />
        )}
        {item.shippingType === SHIPPING_TYPE.home && (
          <small>
            {item.city}
            {item.district}
            {item.detail || item.address}
          </small>
        )}

        {item.shippingType === SHIPPING_TYPE.cvs && (
          <FlexCol>
            <strong>{item.storeName}</strong>
            <small>{item.storeAddr}</small>
          </FlexCol>
        )}
      </FlexRow>
      <div className="filler" />
      {isEnableEdit && (
        <UnderlineButton
          disabled={addrs.length <= 0}
          onClick={() => {
            Actions.setGlobalModalContent(
              <EditAddress
                addrs={addrs}
                fetchAddress={fetchAddress}
                AddCvsStoreButton={AddCvsStoreButton}
                AddAddressButton={AddAddressButton}
                initSelectedAddrId={initSelectedAddrId}
                setSelectedAddressId={setSelectedAddressId}
                item={item}
              />,
            );
          }}>
          {editButtonWording}
        </UnderlineButton>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(FlexRow)`
  background-color: ${Color.mainDark_03};
  padding: 20px;
  align-items: stretch;

  & .filler {
    flex: 1;
  }

  & .customer-info {
    & > strong {
      font-size: 14px;
      font-weight: 500;
    }
    & > small {
      font-size: 14px;
      color: ${Color.mainDark_70};
    }
  }
  & > .separate {
    width: 1px;
    background-color: ${Color.mainDark_10};
    margin-left: 46px;
    margin-right: 20px;
  }
  & > .address-info {
    & > .icon {
      width: 28px;
      height: 28px;
      margin-right: 14px;
    }
    & small {
      font-size: 14px;
      font-weight: 300;
    }
  }
`;
