import {Color, FlexCol, FlexRow} from '../Widget';
import React, {Fragment, useRef, useState} from 'react';
import styled from 'styled-components';
import {Actions} from '../../Contexts/AppContext';
import RectButton, {BUTTON_SIZE, BUTTON_SKIN} from '../RectButton';
import {SHIPPING_TYPE} from '../../Domain/Order';
import {AddressCheckButton} from './AddressCheckButton';
import {useDimension} from '../../Hooks/AppHooks';
import Overlay from '../../Templates/ProfilePage/Overlay';

const EditAddress = props => {
  const {
    item,
    fetchAddress,
    initSelectedAddrId,
    addrs,
    AddCvsStoreButton,
    AddAddressButton,
    setSelectedAddressId,
  } = props;
  const [selectedId, setSelectedId] = useState(initSelectedAddrId);
  const overlayRef = useRef();
  const {mobile, tablet, dimension} = useDimension();
  const smallDevice = mobile || tablet;
  const {innerHeight} = dimension;

  const addressItems = addrs.filter(
    addr => addr.shippingType === item.shippingType,
  );

  const AddBtn =
    item.shippingType === SHIPPING_TYPE.cvs
      ? AddCvsStoreButton
      : AddAddressButton;

  if (addrs.length === 0) {
    return null;
  }

  return (
    <Fragment>
      <Overlay
        mobile={mobile}
        getInstance={inst => {
          overlayRef.current = inst;
        }}
      />
      <Wrapper smallDevice={smallDevice} innerHeight={innerHeight}>
        <FlexCol className="addrs-area">
          {addressItems.map((item, index) => (
            <AddressCheckButton
              setSelectedAddressId={setSelectedAddressId}
              overlayRef={overlayRef}
              fetchAddress={fetchAddress}
              style={{marginBottom: 12}}
              checked={selectedId === item.id}
              onClick={() => setSelectedId(item.id)}
              initSelectedAddrId={initSelectedAddrId}
              item={item}
              key={index}
            />
          ))}
          <AddBtn
            fetchAddress={fetchAddress}
            style={{width: smallDevice ? '100%' : '208px'}}
          />
        </FlexCol>
        <div className="separate" />
        <FlexRow className="action-area">
          <RectButton
            size={BUTTON_SIZE.LARGE}
            skin={BUTTON_SKIN.DEFAULT}
            text="取消"
            onClick={() => {
              Actions.setGlobalModalContent(null);
            }}
          />
          <RectButton
            size={BUTTON_SIZE.LARGE}
            skin={BUTTON_SKIN.PRIMARY}
            text="確定"
            onClick={() => {
              setSelectedAddressId(selectedId);
              Actions.setGlobalModalContent(null);
            }}
          />
        </FlexRow>
      </Wrapper>
    </Fragment>
  );
};

const Wrapper = styled(FlexCol)`
  background-color: ${Color.mainWhite};

  & > .addrs-area {
    padding: ${props => (props.smallDevice ? '40px 20px' : '40px 50px')};
    max-height: ${props => `${props.innerHeight - 300}px`};
    overflow-y: auto;
  }

  & > .separate {
    height: 1px;
    width: 100%;
    background-color: ${Color.mainDark_10};
  }
  & > .action-area {
    justify-content: flex-end;
    padding: 20px 50px;
    & > button {
      width: 160px;
    }
    & > button:first-child {
      margin-right: 12px;
    }
  }
`;

export default EditAddress;
