import React, {useMemo, useState} from 'react';
import styled from 'styled-components';
import {FlexRow} from '../../Components/Widget';
import RectButton, {BUTTON_SKIN} from '../../Components/RectButton';
import Select from '../../Components/Select';
import * as L from '../../Utils/Lang';
import {getRequiredValidator, validateForm} from '../../Utils/validateUtil';
import {brandNameToBrandId} from '../../Domain/Brand';
import {Actions} from '../../Contexts/AppContext';

export const SelectStoreForm = props => {
  const {
    mobile,
    modalRef,
    allStoreData,
    brand,
    storeId,
    fetchFavStores,
  } = props;
  const [values, setValues] = useState({id: storeId});
  const [errors, setErrors] = useState({id: null});
  const validators = useMemo(
    () => ({
      id: getRequiredValidator(),
    }),
    [],
  );

  const onSubmit = async () => {
    const isAllPass = validateForm({values, errors, setErrors, validators});
    if (!isAllPass) {
      return;
    }

    const _post = {
      brand_id: brandNameToBrandId[brand.toLocaleLowerCase()],
      store_id: values.id,
    };

    const isSuccess = await Actions.setFavStore(_post);

    if (!isSuccess) {
      alert('指定服務門市失敗');
      modalRef.current.close();
      return;
    }

    fetchFavStores();
    modalRef.current.close();
  };

  const onValidate = field => e => {
    setErrors({
      ...errors,
      [field]: validators[field].passRequest(values[field]),
    });
  };

  const onValueChange = field => e => {
    setValues({...values, [field]: e.target.value});
  };

  return (
    <ModalContentWrapper mobile={mobile}>
      <Select
        label="選擇門市"
        options={allStoreData[brand].map(store => ({
          display: L.d(store.name),
          value: store.id,
        }))}
        value={values.id}
        onChange={onValueChange('id')}
        onBlur={onValidate('id')}
        error={errors.id}
        style={{flex: 1, marginBottom: 24}}
      />

      <FlexRow style={{justifyContent: 'space-evenly'}}>
        <RectButton
          text={'取消選擇'}
          skin={BUTTON_SKIN.DEFAULT}
          onClick={() => {
            modalRef.current.close();
          }}
        />
        <RectButton
          text={'確定門市'}
          skin={BUTTON_SKIN.PRIMARY}
          onClick={onSubmit}
        />
      </FlexRow>
    </ModalContentWrapper>
  );
};

const ModalContentWrapper = styled.div`
  padding: 20px;
  background-color: white;
  position: relative;
  width: ${props => (props.mobile ? 'unset' : '420px')};

  & > h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
  }

  & > p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    color: #ccc;
    margin-bottom: 32px;
  }
`;
