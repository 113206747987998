import React from 'react';
import styled from 'styled-components';

const Context = React.createContext();

function Provider(props) {
  const {children} = props;
  const [config, setConfig] = React.useState(mobileConfig);
  const [rwd, setRwd] = React.useState('mobile');
  const {dimension} = useDimension();

  const innerWidth = dimension?.innerWidth || 768;

  React.useEffect(() => {
    if (innerWidth > 768) {
      if (config !== desktopConfig) {
        setConfig(desktopConfig);
        setRwd('desktop');
      }
    } else {
      if (config !== mobileConfig) {
        setConfig(mobileConfig);
        setRwd('mobile');
      }
    }
  }, [config, innerWidth]);

  return <Context.Provider value={{rwd, config}}>{children}</Context.Provider>;
}

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

function Box(props) {
  const {children, row, col, size, style = {}, ...extraProps} = props;
  const {config} = React.useContext(Context);
  const paddingStyle = (config[size] || config.none).padding;

  if (row) {
    return (
      <Row style={{...style, ...paddingStyle}} {...extraProps}>
        {children}
      </Row>
    );
  } else if (col) {
    return (
      <Col style={{...style, ...paddingStyle}} {...extraProps}>
        {children}
      </Col>
    );
  } else {
    return (
      <div style={{...style, ...paddingStyle}} {...extraProps}>
        {children}
      </div>
    );
  }
}

function Txt(props) {
  const {children, row, size, style = {}, ...extraProps} = props;
  const {config} = React.useContext(Context);
  const fontStyle = (config[size] || config.none).font;

  if (row) {
    return (
      <span style={{...style, ...fontStyle}} {...extraProps}>
        {children}
      </span>
    );
  }

  return (
    <div style={{...style, ...fontStyle}} {...extraProps}>
      {children}
    </div>
  );
}

function Pad(props) {
  const {row, outer, size} = props;
  const {config} = React.useContext(Context);
  const paddingStyle = (config[size] || config.none).padding;
  const style = {};

  if (row) {
    style.width = paddingStyle.paddingLeft;
  } else {
    style.height = paddingStyle.paddingTop;
  }

  return <div style={style} />;
}

function Sep(props) {
  const {
    row,
    top,
    right,
    bottom,
    left,
    size,
    children,
    style = {},
    ...extraProps
  } = props;
  const {config} = React.useContext(Context);
  const paddingStyle = (config[size] || config.none).padding;
  const marginStyle = {};

  if (row) {
    if (left && !right) {
      marginStyle.marginLeft = paddingStyle.paddingLeft;
    } else if (right && !left) {
      marginStyle.marginRight = paddingStyle.paddingRight;
    } else {
      marginStyle.marginLeft = paddingStyle.paddingLeft;
      marginStyle.marginRight = paddingStyle.paddingRight;
    }
  } else {
    if (top && !bottom) {
      marginStyle.marginTop = paddingStyle.paddingTop;
    } else if (bottom && !top) {
      marginStyle.marginBottom = paddingStyle.paddingBottom;
    } else {
      marginStyle.marginTop = paddingStyle.paddingTop;
      marginStyle.marginBottom = paddingStyle.paddingTop;
    }
  }

  return (
    <div style={{...marginStyle, ...style}} {...extraProps}>
      {children}
    </div>
  );
}

Box.S = styled(Box).attrs({size: 'S'})``;
Box.M = styled(Box).attrs({size: 'M'})``;
Box.L = styled(Box).attrs({size: 'L'})``;
Box.XL = styled(Box).attrs({size: 'XL'})``;
Box.XXL = styled(Box).attrs({size: 'XXL'})``;

Txt.S = styled(Txt).attrs({size: 'S'})``;
Txt.M = styled(Txt).attrs({size: 'M'})``;
Txt.L = styled(Txt).attrs({size: 'L'})``;
Txt.XL = styled(Txt).attrs({size: 'XL'})``;
Txt.XXL = styled(Txt).attrs({size: 'XXL'})``;

Sep.S = styled(Sep).attrs({size: 'S'})``;
Sep.M = styled(Sep).attrs({size: 'M'})``;
Sep.L = styled(Sep).attrs({size: 'L'})``;
Sep.XL = styled(Sep).attrs({size: 'XL'})``;
Sep.XXL = styled(Sep).attrs({size: 'XXL'})``;

Pad.S = styled(Pad).attrs({size: 'S'})``;
Pad.M = styled(Pad).attrs({size: 'M'})``;
Pad.L = styled(Pad).attrs({size: 'L'})``;
Pad.XL = styled(Pad).attrs({size: 'XL'})``;
Pad.XXL = styled(Pad).attrs({size: 'XXL'})``;
Pad.Flex = styled.div`
  flex: 1;
`;

const desktopConfig = {
  none: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
    font: {
      fontSize: 14,
      lineHeight: 1.33,
    },
  },

  S: {
    padding: {
      paddingTop: 4,
      paddingBottom: 4,
      paddingLeft: 8,
      paddingRight: 8,
    },
    font: {
      fontSize: 12,
      lineHeight: 1.33,
    },
  },

  M: {
    padding: {
      paddingTop: 7,
      paddingBottom: 7,
      paddingLeft: 14,
      paddingRight: 14,
    },
    font: {
      fontSize: 14,
      lineHeight: 1.33,
    },
  },

  L: {
    padding: {
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 20,
      paddingRight: 20,
    },
    font: {
      fontSize: 20,
      lineHeight: 1.33,
    },
  },

  XL: {
    padding: {
      paddingTop: 24,
      paddingBottom: 24,
      paddingLeft: 32,
      paddingRight: 32,
    },
    font: {
      fontSize: 24,
      lineHeight: 1.33,
    },
  },

  XXL: {
    padding: {
      paddingTop: 40,
      paddingBottom: 40,
      paddingLeft: 50,
      paddingRight: 50,
    },
    font: {
      fontSize: 30,
      lineHeight: 1.27,
    },
  },
};

const mobileConfig = {
  none: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
    font: {
      fontSize: 14,
      lineHeight: 1.33,
    },
  },

  S: {
    padding: {
      paddingTop: 4,
      paddingBottom: 4,
      paddingLeft: 8,
      paddingRight: 8,
    },
    font: {
      fontSize: 12,
      lineHeight: 1.33,
    },
  },

  M: {
    padding: {
      paddingTop: 7,
      paddingBottom: 7,
      paddingLeft: 14,
      paddingRight: 14,
    },
    font: {
      fontSize: 14,
      lineHeight: 1.33,
    },
  },

  L: {
    padding: {
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 20,
      paddingRight: 20,
    },
    font: {
      fontSize: 18,
      lineHeight: 1.33,
    },
  },

  XL: {
    padding: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
    },
    font: {
      fontSize: 22,
      lineHeight: 1.33,
    },
  },

  XXL: {
    padding: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
    },
    font: {
      fontSize: 24,
      lineHeight: 1.27,
    },
  },
};

function useDimension() {
  const [dimension, setDimension] = React.useState({});
  const detectDimension = React.useCallback(() => {
    const nextDimension = {};
    if (typeof window !== undefined) {
      nextDimension.innerWidth = window.innerWidth;
      nextDimension.innerHeight = window.innerHeight;
      setDimension(nextDimension);
    }
  }, []);
  const onResize = React.useCallback(() => {
    detectDimension();
  }, [detectDimension]);

  React.useEffect(() => {
    try {
      detectDimension();
      window.addEventListener('resize', onResize);
      return () => {
        window.removeEventListener('resize', onResize);
      };
    } catch (ex) {
      // bypass
    }
  }, [onResize, detectDimension]);

  return {
    dimension,
  };
}

export {Box, Txt, Pad, Sep, Col, Row, Provider};
